import { CButton, CCol, CModal, CModalBody, CModalFooter, CModalHeader, CRow } from '@coreui/react'
import React, { useEffect, useState } from 'react'
import { getAllInstitutionUserLog, getAllLog } from 'src/Services/ApiService';
import { Authcontext } from '../Context/Context';
import Logger from '../UserLogger/Logger';

const UserLogModel = () => {
    const { state, dispatch } = React.useContext(Authcontext);
    const [log, setLog] = useState(state.log);

    useEffect(() => {
        const getAllLogFuc = async () => {
            let result;
            try {
                result = state.institutionUser ? await getAllInstitutionUserLog() : await getAllLog();
                if (result.success) {
                    console.log(result, "result123");
                    setLog(result.logs);
                }
            } catch (e) {
               console.log(e);
            }
        }
        if (state) {
            getAllLogFuc();
        }
    }, [state]);


    return (
        <>
            <CModal
                style={{ width: 800 }}
                show={state.userLogger}
                onClose={() => { dispatch({ type: "UserLogger" }); }}
            >
                <CModalHeader closeButton><h4 style={{ fontWeight: '400', color: '#fff' }}>KATBOOK LOG!</h4></CModalHeader>
                <CModalBody>
                    <br />
                    <div style={{ maxHeight: '100%', height: 330, padding: 10, overflowY: 'hidden', overflowX: 'hidden', width: '100%' }}>
                        <CRow>
                            <CCol><h5><strong>Platform</strong></h5></CCol>
                            <CCol><h5><strong>Browser</strong></h5></CCol>
                            <CCol><h5><strong>Login Date &amp; Time</strong></h5></CCol>
                            <CCol><h5><strong>IP Address</strong></h5></CCol>
                        </CRow>
                        <div style={{ maxHeight: '100%', height: 250, overflowY: 'scroll', overflowX: 'hidden', width: '100%' }}>
                            {log && log.length > 0 && log.map((x) => (<>
                                <Logger data={x} />
                            </>))}
                        </div>
                    </div>
                </CModalBody>
                <CModalFooter>
                    <CButton
                        color="danger"
                        onClick={() => { dispatch({ type: "UserLogger" }); }}
                    >Cancel</CButton>{' '}
                </CModalFooter>
            </CModal>
        </>
    )
}

export default UserLogModel
