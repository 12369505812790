import React, { } from 'react'
import {
  CDropdown,
  CDropdownItem,
  CDropdownMenu,
  CDropdownToggle,
  // CImg,
} from '@coreui/react'
import { FiLogOut } from 'react-icons/fi'
import CIcon from '@coreui/icons-react'
import $ from 'jquery'
import { Authcontext } from 'src/views/components/Context/Context'
// import { FaUserAlt } from 'react-icons/fa'
import { MdEmail, FaUserAlt, FaKey } from 'react-icons/all'
import { IoLogoBuffer } from 'react-icons/io5'

const TheHeaderDropdown = ({ 
  view,
  translations,
  languageHandler 
}) => {

  $('#button').click(() => {

  })


  const { state, dispatch } = React.useContext(Authcontext);

  // const user = { img: state.imageUrl ? state.imageUrl : 'avatars/6.jpg', email: state.email ? state.email : '', name: state.user ? state.user : "" }

  return (
    <>
      
      <CDropdown
        inNav
        className="c-header-nav-items mx-2"
        direction="down"

        style={
          view && {
            height: '50px',
            listStyleType: "none"
          }}
      >
        <CDropdownToggle className="c-header-nav-link"
          style={view && {
            marginTop: '0px',
            position: 'relative',
            listStyleType: " none !important"

          }}
          caret={false}>
          <span style={{
            fontSize: 16,
            color: "white",
            fontWeight: "bold",
          }}>{state.adminName ? state.adminName : state.user}</span>&nbsp;&nbsp;
          <span style={{
            fontSize: 16,
            color: "white",
            fontWeight: "bold",
          }}>
          {state.adminName ? state.standard : ''}   {state.adminName ? state.section : ''}</span> &nbsp;  &nbsp;
          <div className="c-avatar" style={{ backgroundColor: view ? 'white' : '#1f4671' }}>
            {/* {user && user.img ? <CImg
            src={user.img}
            className="c-avatar-img"
            alt="admin@bootstrapmaster.com"
          /> : <h5>{user.name}</h5>} */}
            <CIcon name="cil-user" style={{ color: view ? '' : '#fff' }} className="mfe-10" />

          </div>
        </CDropdownToggle>
        <CDropdownMenu className="pt-0" placement="bottom-end">
          <CDropdownItem
            header
            tag="div"
            className="text-center cursor"
            color="black"
          >
            <strong>{translations ? translations.Account : 'Account'}</strong>
          </CDropdownItem>
          <CDropdownItem header className="cursor" >
            <FaUserAlt className="mfe-2" />
            {state.adminName ? state.adminName : state.user}
          </CDropdownItem>
          <CDropdownItem header className="cursor">
            {state.email ? <><MdEmail className="mfe-2" />
              {state.email}</> : <>  <FaKey className="mfe-2" /> {state.user}  </>}
          </CDropdownItem>
          { translations && 
          <CDropdownItem header className="cursor">
              <div className={ state.stateColor === "#294d4a" ? "transList Tam1" : state.stateColor === "#058ED9" ? "transList Tel1" : "transList Comm1" }>
                {/* <span>Change Language: </span> */}
                <select onChange={(e) => languageHandler(e.target.value)} className={"Trans_dropdown"} style={{width: "140px"}}>
                  <option value="" disabled>{translations.language}</option>
                  <option value="en" Selected>English</option>
                  <option value="ch">Chinese</option>
                  <option value="fr">French</option>
                  <option value="ge">German</option>
                  <option value="it">Italian</option>
                  <option value="ja">Japanese</option>
                  <option value="ko">korean</option>
                  <option value="sp">Spanish</option>
                  <option value="ur">Urdu</option>
                </select>
            </div>
          </CDropdownItem>
          }
          {/* <CDropdownItem
          header
          tag="div"
          color="light"
          className="text-center"
        >
          <strong>Settings</strong>
        </CDropdownItem>
        <CDropdownItem>
          <CIcon name="cil-user" className="mfe-2" />Profile
        </CDropdownItem>
        <CDropdownItem>
          <CIcon name="cil-settings" className="mfe-2" /> 
          Settings
        </CDropdownItem>
        <CDropdownItem>
          <CIcon name="cil-credit-card" className="mfe-2" /> 
          Payments
          <CBadge color="secondary" className="mfs-auto">42</CBadge>
        </CDropdownItem>
        <CDropdownItem>
          <CIcon name="cil-file" className="mfe-2" /> 
          Projects
          <CBadge color="primary" className="mfs-auto">42</CBadge>
        </CDropdownItem>*/}
          <CDropdownItem divider />
          { (state.userType === 'Admin' || state.userType === 'Content Developer' || state.userType === 'Content Uploader') ? (
            <>
          <CDropdownItem header className="cursor" onClick={() => { dispatch({ type: "UserLogger" }); }}>
            <IoLogoBuffer className="mfe-2" /> User Log
          </CDropdownItem>
          <CDropdownItem className="cursor" onClick={() => {
            dispatch({ type: 'LOGOUT' });
            window.location = '/'
          }}>
            <FiLogOut className="mfe-2" />
            {translations ? translations.Logaccount : "Log Account"}
          {/* {console.log(state,"HEADERLOG") } */}
        </CDropdownItem >
          </>
          ) : (
            <>
          {/* <CDropdownItem header className="cursor" onClick={() => { dispatch({ type: "UserLogger" }); }}>
            <IoLogoBuffer className="mfe-2" /> User Log
          </CDropdownItem> */}
          <CDropdownItem className="cursor" onClick={() => {
            dispatch({ type: 'LOGOUT' });
            window.close();
          }}>
            <FiLogOut className="mfe-2" />
            {translations ? translations.Logaccount : "Log Account"}
          {/* {console.log(state,"HEADERLOG") } */}
        </CDropdownItem >
          </>
          )
        }
        </CDropdownMenu>
      </CDropdown>
    </>
  )
}

export default TheHeaderDropdown
