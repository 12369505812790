/* eslint-disable no-unused-vars */
import axios from "axios";
// import { json } from "d3";
// import { useContext } from "react";
import { toast } from "react-toastify";
const token = localStorage.getItem("token");

// const kampusUrl = "https://api.kampus.org.in/KampusWebService/";

const kampusUrl = "https://demoapi.ueducate.in/";

// const kampusUrl = "http://192.168.2.209:8080/KampusWebService/";

const URL = `https://api1.katbook.com/v1`;
const URL1 = `https://api1.katbook.com/v2`;

// const URL = `http://192.168.2.33:2504/v1`;
// const URL1 = `http://192.168.2.33:2504/v2`;

// const URL = `http://49.207.184.116:2504/v1`;
// const URL1 = `http://49.207.184.116:2504/v2`;

// create content
export async function updateContentList(
  companyId,
  countryId,
  institutionTypeId,
  contentId,
  body
) {
  try {
    const token = localStorage.getItem(`token`);
    const response = await fetch(
      `${URL}/content/update/${contentId}/${countryId}/${institutionTypeId}/${companyId}`,
      {
        method: `PUT`,
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
        body: JSON.stringify(body),
      }
    );
    if (response.status === 200) {
      return await response.json();
    } else if (response.status === 401 || response.status === 400) {
      return await response.json();
    } else {
      var errorResponse = await response.json();
      throw new Error(errorResponse.error);
    }
  } catch (error) {}
}

//content Link

export async function updateContentLink(
  companyId,
  countryId,
  institutionTypeId,
  contentId,
  body
) {
  try {
    const token = localStorage.getItem(`token`);
    const response = await fetch(
      `${URL}/content/link/update/${contentId}/${countryId}/${institutionTypeId}/${companyId}`,
      {
        method: `PUT`,
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
        body: JSON.stringify(body),
      }
    );
    if (response.status === 200) {
      return await response.json();
    } else if (response.status === 401 || response.status === 400) {
      return await response.json();
    } else {
      var errorResponse = await response.json();
      throw new Error(errorResponse.error);
    }
  } catch (error) {}
}

export async function createCountry(body) {
  const token = localStorage.getItem(`token`);
  // console.log(body);
  try {
    const response = await fetch(`${URL}/country/add`, {
      method: `post`,
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
      body: body,
    });
    // console.log(`addSection-Response`, response);
    return await response.json();
  } catch (e) {
    throw e;
  }
}

export async function createArea(body) {
  const token = localStorage.getItem(`token`);
  // console.log(body);
  try {
    const response = await fetch(`${URL}/area/add`, {
      method: `post`,
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
      body: body,
    });
    // console.log(`addSection-Response`, response);
    return await response.json();
  } catch (e) {
    throw e;
  }
}

export async function getCity(country, state) {
  const token = localStorage.getItem(`token`);
  // console.log(id, `CONID`);
  try {
    const response = await fetch(`${URL}/cities/${country}/${state}`, {
      method: `get`,
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    });
    // console.log(`addSection-Response`, response);
    return await response.json();
  } catch (e) {
    throw e;
  }
}

export async function createState(body) {
  const token = localStorage.getItem(`token`);
  // console.log(body);
  try {
    const response = await fetch(`${URL}/state/add`, {
      method: `post`,
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
      body: body,
    });
    // console.log(`addSection-Response`, response);
    return await response.json();
  } catch (e) {
    throw e;
  }
}

// export async function createEducation(body) {

export async function createCity(body) {
  const token = localStorage.getItem(`token`);
  // console.log(body);
  try {
    const response = await fetch(`${URL}/city/add`, {
      method: `post`,
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
      body: body,
    });
    // console.log(`addSection-Response`, response);
    return await response.json();
  } catch (e) {
    throw e;
  }
}

export async function updateArea(
  areaName,
  areaAbbreviation,
  areaCode,
  pincode,
  country,
  state,
  city,
  area
) {
  // console.log("LOG",countryName, areaName, areaAbbreviation, areaCode, pincode, country, state, city, area);
  const token = localStorage.getItem("token");
  const response = await fetch(
    `${URL}/area/${country}/${state}/${city}/${area}`,
    {
      method: "put",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
      body: JSON.stringify({
        // countryName: countryName,
        areaName: areaName,
        abbreviation: areaAbbreviation,
        code: areaCode,
        pincode: pincode,
        // id: id,
      }),
    }
  );
  // console.log(`updateUser Response`, response);
  if (response.status === 200) {
    return await response.json();
  } else if (response.status === 401 || response.status === 400) {
    return await response.json();
  } else {
    let errorResponse = await response.json();
    throw new Error(errorResponse.error);
  }
}

export async function updateCountry(
  countryName,
  countryAbbreviation,
  countryCode,
  countryId
  // id,
  //  countryAbbreviation, countryCode, id
) {
  // console.log(countyName, countryAbbreviation, countryCode, id);
  const token = localStorage.getItem("token");
  const response = await fetch(`${URL}/country/${countryId}`, {
    method: "put",
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
    body: JSON.stringify({
      countryName: countryName,
      countryAbbreviation: countryAbbreviation,
      countryCode: countryCode,
      // id:id,
    }),
  });
  // console.log(`updateUser Response`, response);
  if (response.status === 200) {
    return await response.json();
  } else if (response.status === 401 || response.status === 400) {
    return await response.json();
  } else {
    let errorResponse = await response.json();
    throw new Error(errorResponse.error);
  }
}

export async function updateState(
  stateName,
  stateAbbreviation,
  stateCode,
  country,
  state
) {
  // console.log(stateName, stateAbbreviation, stateCode);
  const token = localStorage.getItem(`token`);
  const response = await fetch(`${URL}/state/${country}/${state}`, {
    method: `put`,
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
    body: JSON.stringify({
      stateName: stateName,
      stateAbbreviation: stateAbbreviation,
      stateCode: stateCode,
      // id: id,
    }),
  });
  // console.log(`updateUser Response`, response);
  if (response.status === 200) {
    return await response.json();
  } else if (response.status === 401 || response.status === 400) {
    return await response.json();
  } else {
    let errorResponse = await response.json();
    throw new Error(errorResponse.error);
  }
}

export async function updateCity(
  cityName,
  cityAbbreviation,
  cityCode,
  country,
  state,
  city
) {
  // console.log(cityName, cityAbbreviation, cityCode);
  const token = localStorage.getItem(`token`);
  const response = await fetch(`${URL}/city/${country}/${state}/${city}`, {
    method: `put`,
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
    body: JSON.stringify({
      cityName: cityName,
      cityAbbreviation: cityAbbreviation,
      cityCode: cityCode,
    }),
  });
  // console.log(`updateUser Response`, response);
  if (response.status === 200) {
    return await response.json();
  } else if (response.status === 401 || response.status === 400) {
    return await response.json();
  } else {
    let errorResponse = await response.json();
    throw new Error(errorResponse.error);
  }
}

export async function getState(country) {
  const token = localStorage.getItem(`token`);
  const response = await fetch(`${URL}/states/${country}`, {
    method: `GET`,
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
  });

  // console.log(`Response`, response);
  if (response.status === 200) {
    return await response.json();
  } else if (response.status === 401 || response.status === 400) {
    return await response.json();
  } else {
    let errorResponse = await response.json();
    // console.log(`officeType fetching failed`, errorResponse.error);
  }
}

export async function getArea(country, state, city) {
  const token = localStorage.getItem(`token`);
  const response = await fetch(`${URL}/areas/${country}/${state}/${city}`, {
    method: `get`,
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
  });
  // console.log(`Response`, response);
  if (response.status === 200) {
    return await response.json();
  } else if (response.status === 401 || response.status === 400) {
    return await response.json();
  } else {
    let errorResponse = await response.json();
    throw new Error(errorResponse.error);
  }
}

export async function getAreaSchema() {
  const token = localStorage.getItem(`token`);
  const response = await fetch(`${URL}/areas`, {
    method: `get`,
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
  });
  // console.log(`Response`, response);
  if (response.status === 200) {
    return await response.json();
  } else if (response.status === 401 || response.status === 400) {
    return await response.json();
  } else {
    let errorResponse = await response.json();
    throw new Error(errorResponse.error);
  }
}

export async function getCountry() {
  const token = localStorage.getItem(`token`);
  const response = await fetch(`${URL}/countries`, {
    method: `get`,
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
  });
  // console.log(`Response`, response);
  if (response.status === 200) {
    return await response.json();
  } else if (response.status === 401 || response.status === 400) {
    return await response.json();
  } else {
    let errorResponse = await response.json();
    throw new Error(errorResponse.error);
  }
}

export async function deleteCountry(id) {
  try {
    const token = localStorage.getItem(`token`);
    const response = await fetch(`/deleteCountry/${id}`, {
      method: `delete`,
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    });
    // console.log(`delete school response...`, response);
    if (response.status === 200) {
      return await response.json();
    } else if (response.status === 401 || response.status === 400) {
      return await response.json();
    } else {
      var errorResponse = await response.json();
      throw new Error(errorResponse.error);
    }
  } catch (e) {
    throw e;
  }
}

export async function deleteLocation(countryId, stateId, cityId, areaId) {
  // console.log(countryId, "contry");
  let response;
  try {
    const token = localStorage.getItem(`token`);
    response = await fetch(
      `${URL}/location/${countryId}/${stateId}/${cityId}/${areaId}`,
      {
        method: `delete`,
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      }
    );
    // console.log(`delete school response...`, response);
    if (response.status === 200) {
      return await response.json();
    } else if (response.status === 401 || response.status === 400) {
      return await response.json();
    } else {
      var errorResponse = await response.json();
      throw new Error(errorResponse.error);
    }
  } catch (e) {
    throw e;
  }
}

///Role API'S

export async function createRole(body) {
  try {
    const token = localStorage.getItem(`token`);
    const response = await fetch(`${URL}/role/add`, {
      method: `POST`,
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
      body: JSON.stringify(body),
    });
    if (response.status === 200) {
      return await response.json();
    } else if (response.status === 401 || response.status === 400) {
      return await response.json();
    } else {
      var errorResponse = await response.json();
      throw new Error(errorResponse.error);
    }
  } catch (e) {
    throw e;
  }
}

export async function getRole() {
  try {
    const token = localStorage.getItem(`token`);
    const response = await fetch(`${URL}/roles`, {
      method: `GET`,
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    });
    if (response.status === 200) {
      return await response.json();
    } else if (response.status === 401 || response.status === 400) {
      return await response.json();
    } else {
      var errorResponse = await response.json();
      throw new Error(errorResponse.error);
    }
  } catch (e) {
    throw e;
  }
}

export async function updateRole(body, id) {
  try {
    const token = localStorage.getItem(`token`);
    const response = await fetch(`${URL}/update/role/${id}`, {
      method: `PUT`,
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
      body: JSON.stringify(body),
    });
    if (response.status === 200) {
      return await response.json();
    } else if (response.status === 401 || response.status === 400) {
      return await response.json();
    } else {
      var errorResponse = await response.json();
      throw new Error(errorResponse.error);
    }
  } catch (e) {
    throw e;
  }
}

export async function deleteRole(id) {
  try {
    const token = localStorage.getItem(`token`);
    const response = await fetch(`${URL}/delete/role/${id}`, {
      method: `DELETE`,
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    });
    if (response.status === 200) {
      return await response.json();
    } else if (response.status === 401 || response.status === 400) {
      return await response.json();
    } else {
      var errorResponse = await response.json();
      throw new Error(errorResponse.error);
    }
  } catch (e) {
    throw e;
  }
}

export async function getAllAreaPaginate(query) {
  const token = localStorage.getItem(`token`);
  let querys = query || "";
  const response = await fetch(`/getAllAreaPaginate?${querys}`, {
    method: `get`,
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
  });
  // console.log(`Response`, response);
  if (response.status === 200) {
    return await response.json();
  } else if (response.status === 401 || response.status === 400) {
    return await response.json();
  } else {
    let errorResponse = await response.json();
    throw errorResponse.error;
  }
}

export const createLink = async (data) => {
  const token = localStorage.getItem(`token`);
  let response;
  try {
    response = await fetch(`${URL}/link/create`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
      body: JSON.stringify(data),
    });
    if (response.status === 200) {
      return await response.json();
    } else if (response.status === 401 || response.status === 400) {
      return await response.json();
    } else {
      var errorResponse = await response.json();
      throw new Error(errorResponse.error);
    }
  } catch (e) {
    throw e;
  }
};
export const getAllLink = async (page, limit) => {
  const token = localStorage.getItem(`token`);
  let response;
  try {
    response = await fetch(`${URL}/links?page=${page}&limit=${limit}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    });
    if (response.status === 200) {
      return await response.json();
    } else if (response.status === 401 || response.status === 400) {
      return await response.json();
    } else {
      var errorResponse = await response.json();
      throw new Error(errorResponse.error);
    }
  } catch (e) {
    throw e;
  }
};
export const updateLinkContent = async (id, data) => {
  const token = localStorage.getItem(`token`);
  let response;
  try {
    response = await fetch(`${URL}/link/update/${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
      body: JSON.stringify(data),
    });
    if (response.status === 200) {
      return await response.json();
    } else if (response.status === 401 || response.status === 400) {
      return await response.json();
    } else {
      var errorResponse = await response.json();
      throw new Error(errorResponse.error);
    }
  } catch (e) {
    throw e;
  }
};
export const deleteLinkContent = async (id) => {
  const token = localStorage.getItem(`token`);
  let response;
  try {
    response = await fetch(`${URL}/link/delete/${id}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    });
    if (response.status === 200) {
      return await response.json();
    } else if (response.status === 401 || response.status === 400) {
      return await response.json();
    } else {
      var errorResponse = await response.json();
      throw new Error(errorResponse.error);
    }
  } catch (e) {
    throw e;
  }
};

export const getStandarWiseLinkContent = async (key) => {
  const token = localStorage.getItem(`token`);
  let response;
  try {
    response = await fetch(`${URL}/link/standard/find?keyword=${key}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    });
    if (response.status === 200) {
      return await response.json();
    } else if (response.status === 401 || response.status === 400) {
      return await response.json();
    } else {
      let errorResponse = await response.json();
      throw new Error(errorResponse.error);
    }
  } catch (e) {
    return new Error(e);
  }
};
export const getSectionWiseLinkContent = async (key) => {
  const token = localStorage.getItem(`token`);
  let response;
  try {
    response = await fetch(`${URL}//link/section/find?keyword=${key}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    });
    if (response.status === 200) {
      return await response.json();
    } else if (response.status === 401 || response.status === 400) {
      return await response.json();
    } else {
      let errorResponse = await response.json();
      throw new Error(errorResponse.error);
    }
  } catch (e) {
    return new Error(e);
  }
};
export const getSubjectWiseLinkContent = async (key) => {
  const token = localStorage.getItem(`token`);
  let response;
  try {
    response = await fetch(`${URL}//link/section/find?keyword=${key}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    });
    if (response.status === 200) {
      return await response.json();
    } else if (response.status === 401 || response.status === 400) {
      return await response.json();
    } else {
      let errorResponse = await response.json();
      throw new Error(errorResponse.error);
    }
  } catch (e) {
    return new Error(e);
  }
};
export const getChapterWiseLinkContent = async (key) => {
  const token = localStorage.getItem(`token`);
  let response;
  try {
    response = await fetch(`${URL}/link/chapter/find?keyword=${key}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    });
    if (response.status === 200) {
      return await response.json();
    } else if (response.status === 401 || response.status === 400) {
      return await response.json();
    } else {
      let errorResponse = await response.json();
      throw new Error(errorResponse.error);
    }
  } catch (e) {
    return new Error(e);
  }
};
export const getSessionWiseLinking = async (key) => {
  const token = localStorage.getItem(`token`);
  let response;
  try {
    response = await fetch(`${URL}/link/session/find?keyword=${key}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    });
    if (response.status === 200) {
      return await response.json();
    } else if (response.status === 401 || response.status === 400) {
      return await response.json();
    } else {
      let errorResponse = await response.json();
      throw new Error(errorResponse.error);
    }
  } catch (e) {
    return new Error(e);
  }
};

export const getPAthFileWiseLinkContent = async () => {
  const token = localStorage.getItem(`token`);
  let response;
  try {
    response = await fetch(
      `${URL}/link/path/find?keyword=https://samacheerkalvi.guru/samacheer-kalvi-9th-social-science-history-solutions-chapter-1/`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      }
    );
    if (response.status === 200) {
      return await response.json();
    } else if (response.status === 401 || response.status === 400) {
      return await response.json();
    } else {
      let errorResponse = await response.json();
      throw new Error(errorResponse.error);
    }
  } catch (e) {
    return new Error(e);
  }
};
export const wordWiseLinkContnent = async (key) => {
  const token = localStorage.getItem(`token`);
  let response;
  try {
    response = await fetch(`${URL}/link/word/find?keyword=${key}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    });
    if (response.status === 200) {
      return await response.json();
    } else if (response.status === 401 || response.status === 400) {
      return await response.json();
    } else {
      let errorResponse = await response.json();
      throw new Error(errorResponse.error);
    }
  } catch (e) {
    return new Error(e);
  }
};

export const deleteLinkCoontent = async (id) => {
  const token = localStorage.getItem(`token`);
  let response;
  try {
    response = await fetch(`${URL}/link/delete/${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    });
    if (response.status === 200) {
      return await response.json();
    } else if (response.status === 401 || response.status === 400) {
      return await response.json();
    } else {
      let errorResponse = await response.json();
      throw new Error(errorResponse.error);
    }
  } catch (e) {
    return new Error(e);
  }
};

//Katbook attribute Api

export async function getAllCountry() {
  const token = localStorage.getItem(`token`);
  try {
    const response = await fetch(`${URL}/countries`, {
      method: `GET`,
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    });
    if (response.status === 200) {
      return await response.json();
    } else if (response.status === 401 || response.status === 400) {
      return await response.json();
    } else {
      var errorResponse = await response.json();
      throw new Error(errorResponse.error);
    }
  } catch (e) {
    throw e;
  }
}

export async function getInstitutionTypeById(countryId) {
  const token = localStorage.getItem(`token`);
  try {
    const response = await fetch(
      `${URL}/institute/getAllByCountry/${countryId}`,
      {
        method: `GET`,
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      }
    );
    if (response.status === 200) {
      return await response.json();
    } else if (response.status === 401 || response.status === 400) {
      return await response.json();
    } else {
      var errorResponse = await response.json();
      throw new Error(errorResponse.error);
    }
  } catch (error) {}
}

export async function deleteCreate(
  companyId,
  countryId,
  institutionTypeId,
  contentId
) {
  try {
    const token = localStorage.getItem(`token`);
    const response = await fetch(
      `${URL}/content/delete/${contentId}/${countryId}/${institutionTypeId}/${companyId}`,
      {
        method: `DELETE`,
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      }
    );
    if (response.status === 200) {
      return await response.json();
    } else if (response.status === 401 || response.status === 400) {
      return await response.json();
    } else {
      var errorResponse = await response.json();
      throw new Error(errorResponse.error);
    }
  } catch (e) {
    throw e;
  }
}

//create Link

export async function deleteCreateLink(
  companyId,
  countryId,
  institutionTypeId,
  contentId
) {
  try {
    const token = localStorage.getItem(`token`);
    const response = await fetch(
      `${URL}/content/link/delete/${contentId}/${countryId}/${institutionTypeId}/${companyId}`,
      {
        method: `DELETE`,
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      }
    );
    if (response.status === 200) {
      return await response.json();
    } else if (response.status === 401 || response.status === 400) {
      return await response.json();
    } else {
      var errorResponse = await response.json();
      throw new Error(errorResponse.error);
    }
  } catch (e) {
    throw e;
  }
}

//create Content
export async function getContent(
  companyId,
  countryId,
  institutionTypeId,
  typeOfBook,
  variables
) {
  // console.log(variables);
  const token = localStorage.getItem(`token`);
  const response = await fetch(
    `${URL}/content/getAll/${countryId}/${typeOfBook}/${institutionTypeId}/${companyId}`,
    {
      method: `POST`,
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
      body: JSON.stringify({ variables: variables }),
    }
  );
  // console.log(`Response`, response);
  if (response.status === 200) {
    return await response.json();
  } else if (response.status === 401 || response.status === 400) {
    return await response.json();
  } else {
    let errorResponse = await response.json();
    throw new Error(errorResponse.error);
  }
}

//katbook View
export async function getAllView(
  companyId,
  countryId,
  institutionTypeId,
  typeOfBook,
  body
) {
  const token = localStorage.getItem(`token`);
  // console.log(companyId, countryId, institutionTypeId, body);
  const response = await fetch(
    `${URL}/content/firstFind/${companyId}/${typeOfBook}/${countryId}/${institutionTypeId}`,
    {
      method: `POST`,
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
      body: JSON.stringify(body),
    }
  );
  // console.log(`Response`, response);
  if (response.status === 200) {
    return await response.json();
  } else if (response.status === 401 || response.status === 400) {
    return await response.json();
  } else {
    let errorResponse = await response.json();
    throw new Error(errorResponse.error);
  }
}

//Katbook view Pagination
export const viewPaginate = async (
  companyId,
  countryId,
  institutionTypeId,
  typeOfBook,
  unitId,
  body
) => {
  // const token = localStorage.getItem("token");
  const response = await fetch(
    `${URL}/content/find/${companyId}/${typeOfBook}/${countryId}/${institutionTypeId}/${unitId}`,
    {
      method: `POST`,
      headers: {
        "Content-Type": "application/json",
        // Authorization: token,
      },
      body: JSON.stringify(body),
    }
  );
  // console.log(`Response`, response);
  if (response.status === 200) {
    return await response.json();
  } else if (response.status === 401 || response.status === 400) {
    return await response.json();
  } else {
    let errorResponse = await response.json();
    throw new Error(errorResponse.error);
  }
};

//create link

export async function getLinkContent(
  companyId,
  countryId,
  institutionTypeId,
  typeOfBook,
  variables
) {
  const token = localStorage.getItem(`token`);
  const response = await fetch(
    `${URL}/content/link/getAll/${countryId}/${typeOfBook}/${institutionTypeId}/${companyId}`,
    {
      method: `POST`,
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
      body: JSON.stringify({ variables: variables }),
    }
  );
  // console.log(`Response`, response);
  if (response.status === 200) {
    return await response.json();
  } else if (response.status === 401 || response.status === 400) {
    return await response.json();
  } else {
    let errorResponse = await response.json();
    throw new Error(errorResponse.error);
  }
}

export async function createAttribute(body) {
  const token = localStorage.getItem(`token`);
  // console.log(body);
  try {
    const response = await fetch(`${URL}/attribute/create`, {
      method: `POST`,
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
      body: JSON.stringify(body),
    });
    if (response.status === 200) {
      return await response.json();
    } else if (response.status === 401 || response.status === 400) {
      return await response.json();
    } else {
      var errorResponse = await response.json();
      throw new Error(errorResponse.error);
    }
  } catch (e) {
    throw e;
  }
}

export async function addAttribute(body) {
  const token = localStorage.getItem(`token`);
  // console.log(body);
  try {
    const response = await fetch(`${URL}/attribute/add`, {
      method: `POST`,
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
      body: JSON.stringify(body),
    });
    if (response.status === 200) {
      return await response.json();
    } else if (response.status === 401 || response.status === 400) {
      return await response.json();
    } else {
      var errorResponse = await response.json();
      throw new Error(errorResponse.error);
    }
  } catch (e) {
    throw e;
  }
}

// Create Handbook

export async function createHandbook(body) {
  // console.log(body, "dhshdhsg");
  try {
    const token = localStorage.getItem("token");
    const response = await fetch(`${URL}/content/handbook/create`, {
      method: `POST`,
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
      body: JSON.stringify(body),
    });
    if (response.status === 200) {
      return await response.json();
    } else if (response.status === 401 || response.status === 400) {
      return await response.json();
    } else {
      var errorResponse = await response.json();
      throw new Error(errorResponse.error);
    }
  } catch (e) {
    throw e;
  }
}

export async function getHandbookContent(
  companyId,
  countryId,
  institutionTypeId,
  typeOfBook,
  variables
) {
  // console.log(variables);
  const token = localStorage.getItem(`token`);
  const response = await fetch(
    `${URL}/content/handbook/getAll/variables/${countryId}/${typeOfBook}/${institutionTypeId}/${companyId}`,
    {
      method: `POST`,
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
      body: JSON.stringify({ variables: variables }),
    }
  );
  // console.log(`Response`, response);
  if (response.status === 200) {
    return await response.json();
  } else if (response.status === 401 || response.status === 400 || response.status === 404) {
    return await response.json();
  } else {
    let errorResponse = await response.json();
    throw new Error(errorResponse.error);
  }
}


export async function updateHandbookContentList(
  companyId,
  countryId,
  institutionTypeId,
  contentId,
  body
) {
  try {
    const token = localStorage.getItem(`token`);
    const response = await fetch(
      `${URL}/content/handbook/update/${contentId}/${countryId}/${institutionTypeId}/${companyId}`,
      {
        method: `PUT`,
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
        body: JSON.stringify(body),
      }
    );
    if (response.status === 200) {
      return await response.json();
    } else if (response.status === 401 || response.status === 400 || response.status === 404) {
      return await response.json();
    } else {
      var errorResponse = await response.json();
      throw new Error(errorResponse.error);
    }
  } catch (error) {}
}

export async function deleteHandbookContent(
  companyId,
  countryId,
  institutionTypeId,
  contentId
) {
  try {
    const token = localStorage.getItem(`token`);
    const response = await fetch(
      `${URL}/content/handbook/delete/${contentId}/${countryId}/${institutionTypeId}/${companyId}`,
      {
        method: `DELETE`,
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      }
    );
    if (response.status === 200) {
      return await response.json();
    } else if (response.status === 401 || response.status === 400) {
      return await response.json();
    } else {
      var errorResponse = await response.json();
      throw new Error(errorResponse.error);
    }
  } catch (e) {
    throw e;
  }
}
// handbook end

//Create Content

export async function createContent(body) {
  // console.log(body, "dhshdhsg");
  try {
    const token = localStorage.getItem("token");
    const response = await fetch(`${URL}/content/create`, {
      method: `POST`,
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
      body: JSON.stringify(body),
    });
    if (response.status === 200) {
      return await response.json();
    } else if (response.status === 401 || response.status === 400) {
      return await response.json();
    } else {
      var errorResponse = await response.json();
      throw new Error(errorResponse.error);
    }
  } catch (e) {
    throw e;
  }
}

//Create Link

export async function createLinkContent(body) {
  // console.log('data',bodyData);
  try {
    const token = localStorage.getItem("token");
    const response = await fetch(`${URL}/content/link/create`, {
      method: `POST`,
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
      body: JSON.stringify(body),
    });
    if (response.status === 200) {
      return await response.json();
    } else if (response.status === 401 || response.status === 400) {
      return await response.json();
    } else {
      var errorResponse = await response.json();
      throw new Error(errorResponse.error);
    }
  } catch (e) {
    throw e;
  }
}

export async function uploadContentFile(path, id, name, body) {
  // console.log('data',bodyData);
  try {
    const token = localStorage.getItem("token");
    const response = await fetch(`${URL}/content/link/content/upload/file`, {
      method: `PUT`,
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
      body: JSON.stringify({
        path: path,
        contentId: id,
        name: name,
        content: body,
      }),
    });
    if (response.status === 200) {
      return await response.json();
    } else if (response.status === 401 || response.status === 400) {
      return await response.json();
    } else {
      var errorResponse = await response.json();
      throw new Error(errorResponse.error);
    }
  } catch (e) {
    throw e;
  }
}

export async function uploadImage(path, id, name, body) {
  const data = new FormData();
  data.append("socureFile", body);
  const token = localStorage.getItem("token");
  try {
    const response = await axios.put(
      `${URL}/content/link/upload/file?path=${path}&&contentId=${id}&&name=${name}`,
      data,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      }
    );
    // console.log(response);
    if (response.status === 200) {
      return await response.data;
    } else if (response.status === 401 || response.status === 400) {
      return await response.data;
    } else {
      var errorResponse = await response.json();
      throw new Error(errorResponse.error);
    }
  } catch (e) {
    throw e;
  }
}

export async function getDictionary(value) {
  // const token = localStorage.getItem("token");
  // console.log(value);
  try {
    await axios
      .get(`https://api.dictionaryapi.dev/api/v2/entries/en_US/hello`, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        // console.log(response);
        // setData(response.data[0]);
      });

    // if (response.status === 200) {
    //   return await response.data;
    // } else if (response.status === 401 || response.status === 400) {
    //   return await response.data;
    // } else {
    //   var errorResponse = await response.json();
    //   throw new Error(errorResponse.error);
    // }

    // .then((response) => {
    //   // console.log(response);
    //   return response;
    // });

    // const response = await axios.get(
    //   `https://api.dictionaryapi.dev/api/v2/entries/en_US/hello`
    // );
    // console.log(response);
    // return await response;
    // if (response.status === 200) {
    //   return await response.data;
    // } else if (response.status === 401 || response.status === 400) {
    //   return await response.data;
    // } else {
    //   var errorResponse = await response.json();
    //   throw new Error(errorResponse.error);
    // }
  } catch (e) {
    throw e;
  }
}

//Katbook attribute Api

export async function getAttribute(body) {
  const token = localStorage.getItem(`token`);
  // console.log(body);
  try {
    const response = await fetch(
      `${URL}/attribute/${body.company}/${body.typeOfBook._id}/${body.country}/${body.institutionType}`,
      {
        method: `GET`,
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      }
    );
    if (response.status === 200) {
      return await response.json();
    } else if (response.status === 401 || response.status === 400) {
      return await response.json();
    } else {
      var errorResponse = await response.json();
      throw new Error(errorResponse.error);
    }
  } catch (e) {
    throw e;
  }
}

export async function getAllInstitutionData(body) {
  const token = localStorage.getItem(`token`);
  // console.log(body);
  try {
    const response = await fetch(
      `${URL}/institutionName/${body.company}/${body.typeOfBook._id}/${body.country}/${body.institutionType}`,
      {
        method: `GET`,
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      }
    );
    if (response.status === 200) {
      return await response.json();
    } else if (response.status === 401 || response.status === 400) {
      return await response.json();
    } else {
      var errorResponse = await response.json();
      throw new Error(errorResponse.error);
    }
  } catch (e) {
    throw e;
  }
}

export async function getAttributeById(attributeId) {
  const token = localStorage.getItem(`token`);
  try {
    const response = await fetch(`${URL}/attribute/${attributeId}`, {
      method: `GET`,
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    });
    if (response.status === 200) {
      return await response.json();
    } else if (response.status === 401 || response.status === 400) {
      return await response.json();
    } else {
      var errorResponse = await response.json();
      throw new Error(errorResponse.error);
    }
  } catch (e) {
    throw e;
  }
}

export async function updateAttribute(
  company,
  country,
  institutionType,
  tierId,
  body,
  typeOfBook
) {
  const token = localStorage.getItem(`token`);
  // console.log(body);
  try {
    const response = await fetch(
      `${URL}/attribute/update/tier/${company}/${typeOfBook}/${country}/${institutionType}/${tierId}`,
      {
        method: `PUT`,
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
        body: JSON.stringify(body),
      }
    );
    if (response.status === 200) {
      return await response.json();
    } else if (response.status === 401 || response.status === 400) {
      return await response.json();
    } else {
      var errorResponse = await response.json();
      throw new Error(errorResponse.error);
    }
  } catch (e) {
    throw e;
  }
}

//add  variable creation

export async function addTierVariable(tier, body) {
  const token = localStorage.getItem(`token`);
  // console.log("sjsgghdsgdgsd", tier, body);
  try {
    const response = await fetch(`${URL}/variable/create`, {
      method: `POST`,
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
      body: JSON.stringify(body),
    });
    if (response.status === 200) {
      return await response.json();
    } else if (response.status === 401 || response.status === 400) {
      return await response.json();
    } else {
      var errorResponse = await response.json();
      throw new Error(errorResponse.error);
    }
  } catch (e) {
    throw e;
  }
}

export async function updateExercise(
  companyId,
  countryId,
  institutionId,
  _id,
  body
) {
  const token = localStorage.getItem(`token`);
  try {
    const response = await fetch(
      `${URL}/exercise/${companyId}/${countryId}/${institutionId}/${_id}`,
      {
        method: `PUT`,
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
        body: JSON.stringify(body),
      }
    );
    if (response.status === 200) {
      return await response.json();
    } else if (response.status === 401 || response.status === 400) {
      return await response.json();
    } else {
      var errorResponse = await response.json();
      throw new Error(errorResponse.error);
    }
  } catch (e) {
    throw e;
  }
}

export const createExercise = async (data) => {
  let response;
  const token = localStorage.getItem(`token`);
  try {
    response = await fetch(`${URL}/exercise/create`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
      body: JSON.stringify(data),
    });
    if (response.status === 200) {
      return await response.json();
    } else if (response.status === 401 || response.status === 400) {
      return await response.json();
    } else {
      var errorResponse = await response.json();
      throw new Error(errorResponse.error);
    }
  } catch (e) {
    throw e;
  }
};

export const getAllExercise = async (
  companyId,
  countryId,
  institutionTypeId,
  typeOfBook,
  variableId
) => {
  const token = localStorage.getItem(`token`);
  let response;
  let url = variableId
    ? `${URL}/exercises/${companyId}/${typeOfBook}/${countryId}/${institutionTypeId}?variableId=${variableId}`
    : `${URL}/exercises/${companyId}/${typeOfBook}/${countryId}/${institutionTypeId}`;
  try {
    response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    });
    if (response.status === 200) {
      return await response.json();
    } else if (response.status === 401 || response.status === 400) {
      return await response.json();
    } else {
      var errorResponse = await response.json();
      throw new Error(errorResponse.error);
    }
  } catch (e) {
    throw e;
  }
};

export const getAllExerciseData = async (
  companyId,
  countryId,
  institutionTypeId,
  typeOfBook,
  variableId,
  variables
) => {
  const token = localStorage.getItem(`token`);
  let response;
  let url = variableId
    ? `${URL}/exercises/${companyId}/${typeOfBook}/${countryId}/${institutionTypeId}?variableId=${variableId}`
    : `${URL}/exercises/${companyId}/${typeOfBook}/${countryId}/${institutionTypeId}`;
  try {
    response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
      body: JSON.stringify({ variables: variables }),
    });
    if (response.status === 200) {
      return await response.json();
    } else if (response.status === 401 || response.status === 400) {
      return await response.json();
    } else {
      var errorResponse = await response.json();
      throw new Error(errorResponse.error);
    }
  } catch (e) {
    throw e;
  }
};

export async function getVariables(
  company,
  country,
  institutionType,
  attribute,
  parentTierId,
  variableId,
  tierId,
  typeOfBook,
  Code
) {
  const token = localStorage.getItem(`token`);
  // console.log(
  //   "1",
  //   company,
  //   "2",
  //   country,
  //   "3",
  //   institutionType,
  //   "4",
  //   attribute,
  //   "5",
  //   parentTierId,
  //   "6",
  //   variableId,
  //   "7",
  //   tierId,
  //   "8",
  //   typeOfBook
  // );
  try {
    const response = await fetch(
      `${URL}/variables/${company}/${typeOfBook}/${country}/${institutionType}/${attribute}/${parentTierId}/${variableId}/${tierId}/${Code}`,
      {
        method: `GET`,
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      }
    );
    if (response.status === 200) {
      return await response.json();
    } else if (response.status === 401 || response.status === 400) {
      return await response.json();
    } else {
      var errorResponse = await response.json();
      throw new Error(errorResponse.error);
    }
  } catch (e) {
    throw e;
  }
}

export async function getFirtsVariables(
  company,
  country,
  institutionType,
  attribute,
  tierId,
  typeOfBook,
  Code
) {
  const token = localStorage.getItem(`token`);
  // console.log(
  //   company,
  //   country,
  //   institutionType,
  //   attribute,
  //   tierId,
  //   Code,
  //   typeOfBook,
  //   "tytytytyt"
  // );
  try {
    const response = await fetch(
      `${URL}/parent/variables/${company}/${typeOfBook}/${country}/${institutionType}/${attribute}/${tierId}/${Code}`,
      {
        method: `GET`,
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      }
    );
    if (response.status === 200) {
      return await response.json();
    } else if (response.status === 401 || response.status === 400) {
      return await response.json();
    } else {
      var errorResponse = await response.json();
      throw new Error(errorResponse.error);
    }
  } catch (e) {
    throw e;
  }
}

// BookCode Api

export async function createBookCode(id, country, institutionType, body) {
  const token = localStorage.getItem(`token`);
  try {
    const response = await fetch(
      `${URL}/code/${id}/${country}/${institutionType}`,
      {
        method: `POST`,
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
        body: JSON.stringify(body),
      }
    );
    if (response.status === 200) {
      return await response.json();
    } else if (response.status === 401 || response.status === 400) {
      return await response.json();
    } else {
      var errorResponse = await response.json();
      throw new Error(errorResponse.error);
    }
  } catch (e) {
    throw e;
  }
}

export const deleteExercise = async (
  companyId,
  countryId,
  institutionTypeId,
  id
) => {
  const token = localStorage.getItem(`token`);
  let response;
  try {
    response = await fetch(
      `${URL}/exercise/${companyId}/${countryId}/${institutionTypeId}/${id}`,
      {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      }
    );
    if (response.status === 200) {
      return await response.json();
    } else if (response.status === 401 || response.status === 400) {
      return await response.json();
    } else {
      var errorResponse = await response.json();
      throw new Error(errorResponse.error);
    }
  } catch (e) {
    throw e;
  }
};

export const getAllBook = async (country, type) => {
  // const token = localStorage.getItem(`token`);
  let response;
  try {
    response = await fetch(`${URL}/code/get/${country}/${type}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        // Authorization: token,
      },
    });
    if (response.status === 200) {
      return await response.json();
    } else if (response.status === 401 || response.status === 400) {
      return await response.json();
    } else {
      var errorResponse = await response.json();
      throw new Error(errorResponse.error);
    }
  } catch (e) {
    throw e;
  }
};

export const updateVariables = async (variableId, body) => {
  const token = localStorage.getItem(`token`);
  let response;
  try {
    response = await fetch(`${URL}/parent/variables/${variableId}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
      body: JSON.stringify(body),
    });
    if (response.status === 200) {
      return await response.json();
    } else if (response.status === 401 || response.status === 400) {
      return await response.json();
    } else {
      var errorResponse = await response.json();
      throw new Error(errorResponse.error);
    }
  } catch (e) {
    throw e;
  }
};

export const deleteVariable = async (variableId) => {
  const token = localStorage.getItem(`token`);
  let response;
  try {
    response = await fetch(`${URL}/parent/variables/${variableId}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    });
    if (response.status === 200) {
      return await response.json();
    } else if (response.status === 401 || response.status === 400) {
      return await response.json();
    } else {
      var errorResponse = await response.json();
      throw new Error(errorResponse.error);
    }
  } catch (e) {
    throw e;
  }
};
//get Exercise
export async function getExercise(
  company,
  country,
  institutionType,
  exerciseId
) {
  const token = localStorage.getItem(`token`);
  // console.log(company, country, institutionType, exerciseId);
  try {
    const response = await fetch(
      `${URL}/exercise/${company}/${country}/${institutionType}/${exerciseId}`,
      {
        method: `GET`,
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      }
    );
    if (response.status === 200) {
      return await response.json();
    } else if (response.status === 401 || response.status === 400) {
      return await response.json();
    } else {
      var errorResponse = await response.json();
      throw new Error(errorResponse.error);
    }
  } catch (e) {
    throw e;
  }
}

export const createAssessment = async (body) => {
  const token = localStorage.getItem(`token`);
  try {
    const response = await fetch(`${URL}/question/create`, {
      method: `POST`,
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
      body: JSON.stringify(body),
    });
    if (response.status === 200) {
      return await response.json();
    } else if (response.status === 401 || response.status === 400) {
      return await response.json();
    } else {
      var errorResponse = await response.json();
      throw new Error(errorResponse.error);
    }
  } catch (e) {
    throw e;
  }
};

export const getAllAssessment = async (body, typeOfBook) => {
  const token = localStorage.getItem(`token`);
  try {
    const response = await fetch(
      `${URL}/question/${body.company}/${typeOfBook}/${body.country}/${body.institutionType}`,
      {
        method: `POST`,
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
        body: JSON.stringify({ variables: body.variables }),
      }
    );
    if (response.status === 200) {
      return await response.json();
    } else if (response.status === 401 || response.status === 400) {
      return await response.json();
    } else if (response.status === 304) {
      return await response.json();
    } else {
      var errorResponse = await response.json();
      throw new Error(errorResponse.error);
    }
  } catch (e) {
    throw e;
  }
};

export const updateAssessment = async (param, body) => {
  const token = localStorage.getItem(`token`);
  try {
    const response = await fetch(
      `${URL}/question/${param.companyId}/${param.countryId}/${param.institutionTypeId}/${param.questionId}`,
      {
        method: `PUT`,
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
        body: JSON.stringify(body),
      }
    );
    if (response.status === 200) {
      return await response.json();
    } else if (response.status === 401 || response.status === 400) {
      return await response.json();
    } else {
      var errorResponse = await response.json();
      throw new Error(errorResponse.error);
    }
  } catch (e) {
    throw e;
  }
};

export const deleteAssessment = async (body) => {
  const token = localStorage.getItem(`token`);
  try {
    const response = await fetch(
      `${URL}/question/${body.companyId}/${body.countryId}/${body.institutionTypeId}/${body.questionId}`,
      {
        method: `DELETE`,
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      }
    );
    if (response.status === 200) {
      return await response.json();
    } else if (response.status === 401 || response.status === 400) {
      return await response.json();
    } else {
      var errorResponse = await response.json();
      throw new Error(errorResponse.error);
    }
  } catch (e) {
    throw e;
  }
};

export const getAssessmentInfo = async (body) => {
  const token = localStorage.getItem(`token`);
  try {
    const response = await fetch(
      `${URL}/question/${body.companyId}/${body.typeOfBook}/${body.countryId}/${body.institutionType}/${body.questionId}`,
      {
        method: `GET`,
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      }
    );
    if (response.status === 200) {
      return await response.json();
    } else if (response.status === 401 || response.status === 400) {
      return await response.json();
    } else {
      var errorResponse = await response.json();
      throw new Error(errorResponse.error);
    }
  } catch (e) {
    throw e;
  }
};

export const updloadFile = async (
  body,
  variableDetails,
  attributeId,
  publicIp,
  bookId,
  unitId,
  sessionId
) => {
  const token = localStorage.getItem(`token`);
  // console.log("variableDetails", variableDetails["Board Of Affiliation"]);
  const data = new FormData();
  data.append("socureFile", body);
  try {
    const response = await axios.put(
      `${URL}/content/upload/file/${bookId}/${unitId}/${sessionId}`,
      data,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      },
      {}
    );
    console.log(response);
    if (response.status === 200) {
      return await response.data;
    } else if (response.status === 401 || response.status === 400) {
      return await response.data;
    } else {
      var errorResponse = await response.json();
      throw new Error(errorResponse.error);
    }
  } catch (e) {
    throw e;
  }
};

export const updloadVideoFile = async (
  body,
  variableDetails,
  attributeId,
  publicIp,
  bookId,
  unitId,
  sessionId,
  type,
  duration
) => {
  const token = localStorage.getItem(`token`);
  // console.log("variableDetails", variableDetails["Board Of Affiliation"]);
  const data = new FormData();
  data.append("socureFile", body);
  try {
    const response = await axios.post(
      `${URL}/content/upload/file/videos/${bookId}/${unitId}/${sessionId}/${type}/${duration}`,
      data,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      },
      {}
    );
    console.log(response);
    if (response.status === 200) {
      return await response.data;
    } else if (response.status === 401 || response.status === 400) {
      return await response.data;
    } else {
      var errorResponse = await response.json();
      throw new Error(errorResponse.error);
    }
  } catch (e) {
    throw e;
  }
};

export const updloadAudioFile = async (
  body,
  variableDetails,
  attributeId,
  publicIp,
  bookId,
  unitId,
  sessionId,
  type,
  duration
) => {
  const token = localStorage.getItem(`token`);
  // console.log("variableDetails", variableDetails["Board Of Affiliation"]);
  const data = new FormData();
  data.append("socureFile", body);
  try {
    const response = await axios.post(
      `${URL}/content/upload/file/audio/${bookId}/${unitId}/${sessionId}/${type}/${duration}`,
      data,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      },
      {}
    );
    console.log(response);
    if (response.status === 200) {
      return await response.data;
    } else if (response.status === 401 || response.status === 400) {
      return await response.data;
    } else {
      var errorResponse = await response.json();
      throw new Error(errorResponse.error);
    }
  } catch (e) {
    throw e;
  }
};

export const updloadAssessmentFile = async (
  body,
  variableDetails,
  attributeId,
  publicIp,
  bookId,
  unitId
) => {
  const token = localStorage.getItem(`token`);
  // console.log("variableDetails", variableDetails["Board Of Affiliation"]);
  const data = new FormData();
  data.append("socureFile", body);
  try {
    const response = await axios.put(
      `${URL}/assessment/upload/file/${bookId}/${unitId}`,
      data,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      },
      {}
    );
    console.log(response);
    if (response.status === 200) {
      return await response.data;
    } else if (response.status === 401 || response.status === 400) {
      return await response.data;
    } else {
      var errorResponse = await response.json();
      throw new Error(errorResponse.error);
    }
  } catch (e) {
    throw e;
  }
};

//type of book

export const getAllTypeOfBook = async () => {
  try {
    const token = localStorage.getItem(`token`);
    const response = await fetch(`${URL}/booktypes`, {
      method: `GET`,
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    });
    if (response.status === 200) {
      return await response.json();
    } else if (response.status === 401 || response.status === 400) {
      return await response.json();
    } else {
      var errorResponse = await response.json();
      throw new Error(errorResponse.error);
    }
  } catch (e) {
    throw e;
  }
};

//upload cover image

export const uploadCoverImage = async (
  body,
  variableName,
  parentVaribaleName,
  grandParentVaribaleName,
  variableId,
  isaudioBook,
  booklanguage,
  correctAnswer,
  wrongAnswer
) => {
  const token = localStorage.getItem(`token`);
  const data = new FormData();
  data.append("coverImage", body);
  data.append("isAudioBook", isaudioBook);
  data.append("language", booklanguage);
  data.append("correctAnswerAudio", correctAnswer);
  data.append("wrongAnswerAudio", wrongAnswer);
  try {
    const response = await axios.put(
      `${URL}/subject/upload/coverImage?variableName=${variableName}&&parentVaribaleName=${parentVaribaleName}&&grandParentVaribaleName=${grandParentVaribaleName}&&variableId=${variableId}`,
      data,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      },
      {}
    );
    // console.log(response);
    if (response.status === 200) {
      return await response.data;
    } else if (response.status === 401 || response.status === 400 || response.status === 404) {
      return await response.data;
    } else {
      var errorResponse = await response.json();
      throw new Error(errorResponse.error);
    }
  } catch (e) {
    throw e;
  }
};

export const deleteCoverImage = async (varaibleId) => {
  const token = localStorage.getItem(`token`);
  try {
    const response = await fetch(`${URL}/subject/coverImage/${varaibleId}`, {
      method: `DELETE`,
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    });
    if (response.status === 200) {
      return await response.json();
    } else if (response.status === 401 || response.status === 400) {
      return await response.json();
    } else {
      var errorResponse = await response.json();
      throw new Error(errorResponse.error);
    }
  } catch (e) {
    throw e;
  }
};

//Office Type

export async function createOfficeType(body) {
  // console.log(body);
  try {
    const response = await fetch(`${URL}/saveOfficeType`, {
      method: `post`,
      headers: {
        "Content-Type": "application/json",
      },
      body: body,
    });
    // console.log(`addSection-Response`, response);
    return await response.json();
  } catch (e) {
    throw e;
  }
}

// export async function createOfficeType(body) {
//   try{
//     const response = await axios.post(`${URL}/saveOfficeType`, body);
//     if(response){
//       return response.data;
//     }
//   }
//   catch(e){
//     throw e;
//   }
// }

export async function deleteOfficeType(id) {
  try {
    const response = await fetch(`${URL}/deleteOfficeType/${id}`, {
      method: `delete`,
      headers: {
        "Content-Type": "application/json",
      },
    });
    // console.log(`delete school response...`, response);
    if (response.status === 200) {
      return await response.json();
    } else if (response.status === 401 || response.status === 400) {
      return await response.json();
    } else {
      var errorResponse = await response.json();
      // console.log(errorResponse,"123123")
      throw errorResponse.error;
    }
  } catch (e) {
    throw e;
  }
}

export async function getHierarchy() {
  try {
    const response = await fetch(`${URL}/officeType/getHierarchy`, {
      method: `GET`,
      headers: {
        "Content-Type": "application/json",
      },
    });
    if (response.status === 200) {
      return await response.json();
    } else if (response.status === 401 || response.status === 400) {
      return await response.json();
    } else {
      var errorResponse = await response.json();
      throw new Error(errorResponse.error);
    }
  } catch (e) {
    // console.log(e);
  }
}

export async function updateOfficeType(
  OfficeType,
  Abbreviation,
  Code,
  ReportingTo,
  id
) {
  // console.log(OfficeType, Abbreviation, Code, ReportingTo, id);
  const response = await fetch(`${URL}/updateOfficeType`, {
    method: `put`,
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      officeType: OfficeType,
      abbreviation: Abbreviation,
      code: Code,
      reportingTo: ReportingTo,
      id: id,
    }),
  });
  // console.log(`updateUser Response`, response);
  if (response.status === 200) {
    return await response.json();
  } else if (response.status === 401 || response.status === 400) {
    return await response.json();
  } else {
    let errorResponse = await response.json();
    throw new Error(errorResponse.error);
  }
}

export async function getEntityList(query) {
  let querys = query || "";
  const response = await fetch(`${URL}/entity/getAll?${querys}`, {
    method: `get`,
    headers: {
      "Content-Type": "application/json",
    },
  });
  // console.log(`Response`, response);
  if (response.status === 200) {
    return await response.json();
  } else if (response.status === 401 || response.status === 400) {
    return await response.json();
  } else {
    let errorResponse = await response.json();
    throw new Error(errorResponse.error);
  }
}

export async function getProfile() {
  const token = localStorage.getItem(`token`);
  const response = await fetch(`${URL}/admin/profile`, {
    method: `get`,
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
  });
  // console.log(`Response`, response);
  if (response.status === 200) {
    return await response.json();
  } else if (response.status === 401 || response.status === 400) {
    return await response.json();
  } else {
    let errorResponse = await response.json();
    throw new Error(errorResponse.error);
  }
}

//Office Location

export async function deleteOfficeLocation(id) {
  try {
    const response = await fetch(`${URL}/deleteOfficeLocation/${id}`, {
      method: `delete`,
      headers: {
        "Content-Type": "application/json",
      },
    });
    if (response.status === 200) {
      return await response.json();
    } else if (response.status === 401 || response.status === 400) {
      return await response.json();
    } else {
      var errorResponse = await response.json();
      throw errorResponse.error;
    }
  } catch (error) {
    throw error;
  }
}

export async function createOfficeLocation(body) {
  // console.log(JSON.stringify(body), "body");
  try {
    const response = await fetch(`${URL}/saveOfficeLocation`, {
      method: `post`,
      headers: {
        "Content-Type": "application/json",
      },
      body: body,
    });
    // console.log(`Response`, response.body);
    if (response.status === 200) {
      return await response.json();
    } else {
      let errorResponse = await response.json();
      throw new Error(errorResponse.error);
    }
  } catch (e) {
    // console.log(e);
    toast.error(`${e}`);
  }
}

export async function getHierarchyOffice() {
  try {
    const response = await fetch(`${URL}/officeLocation/getHierarchy`, {
      method: `GET`,
      headers: {
        "Content-Type": "application/json",
      },
    });
    if (response.status === 200) {
      return await response.json();
    } else if (response.status === 401 || response.status === 400) {
      return await response.json();
    } else {
      var errorResponse = await response.json();
      throw new Error(errorResponse.error);
    }
  } catch (e) {
    // console.log(e);
  }
}

export async function getOfficeLocationById(id, token) {
  // console.log(id);
  const response = await fetch(`${URL}/employee/getByOfficeLocation?id=${id}`, {
    method: `get`,
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
  });
  // console.log(`Response111`, response);
  if (response.status === 200) {
    return await response.json();
  } else if (response.status === 401 || response.status === 400) {
    return await response.json();
  } else {
    let errorResponse = await response.json();
    throw new Error(errorResponse.error);
  }
}

export async function getOfficeLocation(query) {
  let querys = query || ``;
  const response = await fetch(`${URL}/getAllOfficeLocation?${querys}`, {
    method: `get`,
    headers: {
      "Content-Type": "application/json",
    },
  });
  // console.log(`Response`, response);
  if (response.status === 200) {
    return await response.json();
  } else if (response.status === 401 || response.status === 400) {
    return await response.json();
  } else {
    let errorResponse = await response.json();
    throw new Error(errorResponse.error);
  }
}

export async function updateOfficeLocation(body) {
  // console.log(OfficeType, Address1, Address2, id);
  const response = await fetch(`${URL}/updateOfficeLocation`, {
    method: `PUT`,
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(body),
  });
  if (response.status === 200) {
    // console.log("update location response xxx", await response.json());
    return response;
  } else if (response.status === 401 || response.status === 400) {
    return await response.json();
  } else {
    let errorResponse = await response.json();
    throw new Error(errorResponse.error);
  }
}

//Role Creation

export async function getAllRole(query) {
  let querys = query || ``;
  const response = await fetch(`${URL}/role/getAllRoles?${querys}`, {
    method: `get`,
    headers: {
      "Content-Type": "application/json",
    },
  });
  // console.log(`Response111`, response);
  if (response.status === 200) {
    return await response.json();
  } else if (response.status === 401 || response.status === 400) {
    return await response.json();
  } else {
    let errorResponse = await response.json();
    throw new Error(errorResponse.error);
  }
}

export async function getByOfficeLocation(id) {
  try {
    const response = await fetch(`${URL}/getByOfficeType/${id}`, {
      method: `get`,
      headers: {
        "Content-Type": "application/json",
      },
    });
    // console.log(`addSection-Response`, response);
    if (response.status === 200) {
      return await response.json();
    } else if (response.status === 401 || response.status === 400) {
      return await response.json();
    } else {
      let errorResponse = await response.json();
      throw new Error(errorResponse.error);
    }
  } catch (e) {
    // console.log(e);
  }
}

export async function getOneRole(id) {
  try {
    const response = await fetch(`${URL}/role/getOne?id=${id}`, {
      method: `get`,
      headers: {
        "Content-Type": "application/json",
      },
    });
    // console.log(`future-Response`, response);
    if (response.status === 200) {
      return await response.json();
    } else if (response.status === 401 || response.status === 400) {
      return await response.json();
    } else {
      let errorResponse = await response.json();
      throw new Error(errorResponse.error);
    }
  } catch (e) {
    throw e;
  }
}

export async function getRoleByDesignation(val) {
  const response = await fetch(`${URL}/role/getAllRoles?${val}`, {
    method: `get`,
    headers: {
      "Content-Type": "application/json",
    },
  });
  // console.log(`Response`, response);
  if (response.status === 200) {
    return await response.json();
  } else if (response.status === 401 || response.status === 400) {
    return await response.json();
  } else {
    let errorResponse = await response.json();
    throw new Error(errorResponse.error);
  }
}

// DEPARTMENTCREATE

export async function departmentCreate(body) {
  const response = await fetch(`${URL}/department/create`, {
    method: `post`,
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(body),
  });
  // console.log(`Response`, response);
  if (response.status === 200) {
    return await response.json();
  } else if (response.status === 401 || response.status === 400) {
    return await response.json();
  } else {
    let errorResponse = await response.json();
    throw new Error(errorResponse.error);
  }
}

//designationcreate

export async function designationCreate(body) {
  const response = await fetch(`${URL}/designation/create`, {
    method: `post`,
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(body),
  });
  // console.log(`Response`, response);
  if (response.status === 200) {
    return await response.json();
  } else if (response.status === 401 || response.status === 400) {
    return await response.json();
  } else {
    let errorResponse = await response.json();
    throw new Error(errorResponse.error);
  }
}

//getAllDepartment

export async function getAllDepartment() {
  const response = await fetch(`${URL}/department/getAll`, {
    method: `get`,
    headers: {
      "Content-Type": "application/json",
    },
  });
  // console.log(`Response`, response);
  if (response.status === 200) {
    return await response.json();
  } else if (response.status === 401 || response.status === 400) {
    return await response.json();
  } else {
    let errorResponse = await response.json();
    throw new Error(errorResponse.error);
  }
}
//getAllRoleMaster

export async function getAllRoleMaster(query) {
  let querys = query || ``;
  const response = await fetch(`${URL}/roleMaster/getAll?${querys}`, {
    method: `get`,
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
  });
  // console.log(`Response111`, response);
  if (response.status === 200) {
    return await response.json();
  } else if (response.status === 401 || response.status === 400) {
    return await response.json();
  } else {
    let errorResponse = await response.json();
    throw new Error(errorResponse.error);
  }
}

//getDesignation

export async function getDesignation() {
  const response = await fetch(`${URL}/designation/getAll`, {
    method: `get`,
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
  });
  // console.log(`Response`, response);
  if (response.status === 200) {
    return await response.json();
  } else if (response.status === 401 || response.status === 400) {
    return await response.json();
  } else {
    let errorResponse = await response.json();
    throw new Error(errorResponse.error);
  }
}
//getEmployeeHierarchy

export async function getEmployeeHierarchy(officeId, locationId) {
  try {
    const response = await fetch(
      `${URL}/role/getHierarchy/type/${officeId}/${locationId}`,
      {
        method: `GET`,
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    if (response.status === 200) {
      return await response.json();
    } else if (response.status === 401 || response.status === 400) {
      return await response.json();
    } else {
      var errorResponse = await response.json();
      throw new Error(errorResponse.error);
    }
  } catch (e) {
    // console.log(e);
  }
}

export async function roleCreate(body, administative, functional) {
  let datas = {
    role: body.roleName._id,
    typeOfOffice: body.officetype._id,
    department: body.departmentRole._id,
    designation: body.designationRole._id,
    officeLocation: body.locationRole._id,
    dccDescription: body.dccDescription,
    dccId: body.dccID,
  };
  if (functional) {
    let data = [];
    functional.map((x) => {
      let y = {
        department: x.department._id,
        designation: x.designation._id,
        roleId: x._id,
        role: x.role._id,
        officeLocation: x.officeLocation._id,
        typeOfOffice: x.typeOfOffice._id,
      };
      data.push(y);
      return 0;
    });

    datas = {
      ...datas,
      functionalReporting: data,
    };
  }

  let aData = {};

  if (administative.roleId) {
    aData = {
      department: administative.department._id,
      designation: administative.designation._id,
      roleId: administative.roleId,
      role: administative.role._id,
      officeLocation: administative.officeLocation._id,
      typeOfOffice: administative.typeOfOffice._id,
    };
  }

  datas = {
    ...datas,
    administativeReporting: aData,
  };

  const response = await fetch(`${URL}/role/create`, {
    method: `post`,
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
    body: JSON.stringify(datas),
  });
  // console.log(`Response`, response);
  if (response.status === 200) {
    return await response.json();
  } else if (response.status === 401 || response.status === 400) {
    return await response.json();
  } else {
    let errorResponse = await response;
    throw new Error(errorResponse.error);
  }
}

//  roleDelete
export async function roleDelete(id) {
  const response = await fetch(`${URL}/role/delete`, {
    method: `put`,
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
    body: JSON.stringify({
      id: id,
    }),
  });
  // console.log(`Response`, response);
  if (response.status === 200) {
    return await response.json();
  } else if (response.status === 401 || response.status === 400) {
    return await response.json();
  } else {
    let errorResponse = await response.json();
    throw errorResponse.error;
  }
}
//roleNameCreate

export async function roleNameCreate(body) {
  const response = await fetch(`${URL}/roleMaster/create`, {
    method: `post`,
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
    body: JSON.stringify(body),
  });
  // console.log(`Response`, response);
  if (response.status === 200) {
    return await response.json();
  } else if (response.status === 401 || response.status === 400) {
    return await response.json();
  } else {
    let errorResponse = await response.json();
    throw new Error(errorResponse.error);
  }
}

//Role Copy

export async function getAllRoleData(id) {
  // console.log(id);
  const response = await fetch(`${URL}/role/getAllRole?id=${id}`, {
    method: `get`,
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
  });
  // console.log(`Response111`, response);
  if (response.status === 200) {
    return await response.json();
  } else if (response.status === 401 || response.status === 400) {
    return await response.json();
  } else {
    let errorResponse = await response.json();
    throw new Error(errorResponse.error);
  }
}

export async function createRoleConfirm(data) {
  let body = {
    datas: data,
  };

  const response = await fetch(`${URL}/role/copyRole`, {
    method: `post`,
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
    body: JSON.stringify(body),
  });
  // console.log(`Response`, response);
  if (response.status === 200) {
    return await response.json();
  } else if (response.status === 401 || response.status === 400) {
    return await response.json();
  } else {
    let errorResponse = await response.json();
    throw new Error(errorResponse.error);
  }
}

export async function getAllRoleLocation(id, officeType) {
  // console.log(id);
  let data = {
    id: id,
    officeType: officeType,
  };
  const response = await axios.post(`${URL}/role/getLocationNotExist`, data);
  // console.log(`Response111`, response);
  if (response.status === 200) {
    return await response.data;
  } else if (response.status === 401 || response.status === 400) {
    return await response.json();
  } else {
    let errorResponse = await response.data;
    throw new Error(errorResponse.error);
  }
}

//Employee data

export async function employeeDelete(id) {
  const token = localStorage.getItem(`token`);
  try {
    const response = await fetch(`${URL}/deleteEmployee/${id}`, {
      method: `delete`,
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    });
    // console.log(`addSection-Response`, response);
    return await response.json();
  } catch (e) {
    throw e;
  }
}

export async function getOfficeType(query) {
  const token = localStorage.getItem(`token`);
  // let querys = query || "";
  const response = await fetch(`${URL}/getAllOfficeType`, {
    method: `get`,
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
  });
  // console.log(`Response`, response);
  if (response.status === 200) {
    return await response.json();
  } else if (response.status === 401 || response.status === 400) {
    return await response.json();
  } else {
    let errorResponse = await response.json();
    throw new Error(errorResponse.error);
  }
}

export const updateEmployeeData = async (id) => {
  let response;
  try {
    response = await fetch(`${URL}/getEmployee?id=${id._id}`, {
      method: `GET`,
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    });
    // console.log(`delete school response...`, response);
    if (response.status === 200) {
      return await response.json();
    } else if (response.status === 401 || response.status === 400) {
      return await response.json();
    } else {
      var errorResponse = await response.json();
      throw new Error(errorResponse.error);
    }
  } catch (e) {
    // console.log(e);
  }
};

export async function getEmployeeDataHierarchy(officeId, locationId) {
  try {
    const response = await fetch(
      `${URL}/role/employee/getHierarchy/type/${officeId}/${locationId}`,
      {
        method: `GET`,
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    if (response.status === 200) {
      return await response.json();
    } else if (response.status === 401 || response.status === 400) {
      return await response.json();
    } else {
      var errorResponse = await response.json();
      throw new Error(errorResponse.error);
    }
  } catch (e) {
    // console.log(e);
  }
}
export async function getEmployee(body, query) {
  let querys = query || "";
  try {
    const response = await fetch(`${URL}/getAllEmployee?${querys}`, {
      method: `get`,
      headers: {
        "Content-Type": "application/json",
      },
      body: body,
    });
    // console.log(`addSection-Response`, response);
    return await response.json();
  } catch (e) {
    throw e;
  }
}

//Experience Details

export async function getExperience(body) {
  // console.log(body);
  try {
    const response = await fetch(`${URL}/getAllExperience`, {
      method: `get`,
      headers: {
        "Content-Type": "application/json",
      },
      body: body,
    });
    // console.log(`addSection-Response`, response);
    return await response.json();
  } catch (e) {
    throw e;
  }
}

//Family Details

export async function getFamily(body) {
  // console.log(body);
  try {
    const response = await fetch(`${URL}/getAllFamily`, {
      method: `get`,
      headers: {
        "Content-Type": "application/json",
      },
      body: body,
    });
    // console.log(`addSection-Response`, response);
    return await response.json();
  } catch (e) {
    throw e;
  }
}

export async function addAllEmployee(body) {
  // console.log(body, `apiservice`);
  try {
    const response = await axios.post(`${URL}/saveEmployee`, body);
    // console.log(`addSection-Response`, response);
    if (response.status === 200) {
      return await response.data;
    } else if (response.status === 401 || response.status === 400) {
      return await response.json();
    } else {
      let errorResponse = await response.data.error;
      throw new Error(errorResponse.data.error);
    }
  } catch (e) {
    throw e;
  }
}
// export async function addAllEmployee(body) {
//   const token = localStorage.getItem(`token`);
//   // console.log(body);
//   try {
//     const response = await fetch(`${URL}/saveEmployee`, {
//       method: `post`,
//       headers: {
//         "Content-Type": "application/json",
//         Authorization: token,
//       },
//       body: body,
//     });
//     // console.log(`addSection-Response`, response);
//     return await response.json();
//   } catch (e) {
//     throw e;
//   }
// }
export async function updateEmployee(body) {
  try {
    const response = await axios.put(`${URL}/updateEmployee`, body);
    // console.log(`updateEmployee`, response);
    return await response;
  } catch (e) {
    throw e;
  }
}
export const getAllTitles = async (param) => {
  const token = localStorage.getItem(`token`);
  let response;
  try {
    response = await fetch(
      `${URL}/titles/${param.company}/${param.typeOfBook}/${param.country}/${param.institutionType}/${param.attribute}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      }
    );
    if (response.status === 200) {
      return await response.json();
    } else if (response.status === 401 || response.status === 400) {
      return await response.json();
    } else {
      var errorResponse = await response.json();
      throw new Error(errorResponse.error);
    }
  } catch (e) {
    throw e;
  }
};

//KatBookTitleUpload
export async function getAreaSchemas() {
  const token = localStorage.getItem(`token`);
  const response = await fetch(`${URL}/getAllAreas`, {
    method: `get`,
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
  });
  if (response.status === 200) {
    return await response.json();
  } else if (response.status === 401 || response.status === 400) {
    return await response.json();
  } else {
    let errorResponse = await response.json();
    throw new Error(errorResponse.error);
  }
}

export async function locationUpload(data) {
  try {
    const token = localStorage.getItem(`token`);
    const response = await fetch(`${URL}/officeLocation/bulkUpload`, {
      method: `post`,
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
      body: JSON.stringify(data),
    });
    if (response.status === 200) {
      return await response.json();
    } else if (response.status === 401 || response.status === 400) {
      return await response.json();
    } else {
      var errorResponse = await response.json();
      throw new Error(errorResponse.error);
    }
  } catch (e) {
    // console.log(e);
  }
}

export async function getOfficeTypes(query) {
  const token = localStorage.getItem(`token`);
  let querys = query || "";
  const response = await fetch(`${URL}/getAllOfficeType?${querys}`, {
    method: `get`,
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
  });
  if (response.status === 200) {
    return await response.json();
  } else if (response.status === 401 || response.status === 400) {
    return await response.json();
  } else {
    let errorResponse = await response.json();
    throw new Error(errorResponse.error);
  }
}

//katbook View
export async function getTextSearchContent(
  companyId,
  countryId,
  institutionTypeId,
  typeOfBook,
  body,
  text
) {
  // const token = localStorage.getItem(`token`);
  // console.log(companyId, countryId, institutionTypeId, body, text);
  var bodyData = JSON.parse(body);
  const response = await fetch(
    `${URL}/content/text/${companyId}/${typeOfBook}/${countryId}/${institutionTypeId}`,
    {
      method: `POST`,
      headers: {
        "Content-Type": "application/json",
        // Authorization: token,
      },
      body: JSON.stringify({ ...bodyData, text: text }),
    }
  );
  // console.log(`Response`, response);
  if (response.status === 200) {
    return await response.json();
  } else if (response.status === 401 || response.status === 400) {
    return await response.json();
  } else {
    var errorResponse = await response.json();
    throw new Error(errorResponse.error);
  }
}

export async function getVariablesContent(
  company,
  country,
  institutionType,
  attribute,
  parentTierId,
  variableId,
  tierId,
  typeOfBook,
  Code
) {
  const token = localStorage.getItem(`token`);
  // console.log(
  //   "1",
  //   company,
  //   "2",
  //   country,
  //   "3",
  //   institutionType,
  //   "4",
  //   attribute,
  //   "5",
  //   parentTierId,
  //   "6",
  //   variableId,
  //   "7",
  //   tierId,
  //   "8",
  //   typeOfBook
  // );
  try {
    const response = await fetch(
      `${URL}/variables/content/get/${company}/${typeOfBook}/${country}/${institutionType}/${attribute}/${parentTierId}/${variableId}/${tierId}`,
      {
        method: `GET`,
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      }
    );
    if (response.status === 200) {
      return await response.json();
    } else if (response.status === 401 || response.status === 400) {
      return await response.json();
    } else {
      var errorResponse = await response.json();
      throw new Error(errorResponse.error);
    }
  } catch (e) {
    throw e;
  }
}



export async function getHandbookVariablesContent(
  company,
  country,
  institutionType,
  attribute,
  parentTierId,
  variableId,
  tierId,
  typeOfBook,
  Code
) {
  const token = localStorage.getItem(`token`);
  // console.log(
  //   "1",
  //   company,
  //   "2",
  //   country,
  //   "3",
  //   institutionType,
  //   "4",
  //   attribute,
  //   "5",
  //   parentTierId,
  //   "6",
  //   variableId,
  //   "7",
  //   tierId,
  //   "8",
  //   typeOfBook
  // );
  try {
    const response = await fetch(
      `${URL}/variables/handbook/get/${company}/${typeOfBook}/${country}/${institutionType}/${attribute}/${parentTierId}/${variableId}/${tierId}`,
      {
        method: `GET`,
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      }
    );
    if (response.status === 200) {
      return await response.json();
    } else if (response.status === 401 || response.status === 400) {
      return await response.json();
    } else {
      var errorResponse = await response.json();
      throw new Error(errorResponse.error);
    }
  } catch (e) {
    throw e;
  }
}

//BookType

export async function createBookType(body) {
  const token = localStorage.getItem(`token`);
  // console.log(body);
  try {
    const response = await fetch(`${URL}/booktype/add`, {
      method: `post`,
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
      body: body,
    });
    // console.log(`addSection-Response`, response);
    return await response.json();
  } catch (e) {
    throw e;
  }
}

//update Book Type

export async function updateBookType(booktypeId, body) {
  try {
    const token = localStorage.getItem(`token`);
    const response = await fetch(`${URL}/booktype/${booktypeId}`, {
      method: `PUT`,
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
      body: JSON.stringify(body),
    });
    if (response.status === 200) {
      return await response.json();
    } else if (response.status === 401 || response.status === 400) {
      return await response.json();
    } else {
      var errorResponse = await response.json();
      throw new Error(errorResponse.error);
    }
  } catch (error) {}
}

export async function getBookType(query) {
  const token = localStorage.getItem(`token`);
  // let querys = query || "";
  const response = await fetch(`${URL}/booktypes`, {
    method: `get`,
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
  });
  // console.log(`Response`, response);
  if (response.status === 200) {
    return await response.json();
  } else if (response.status === 401 || response.status === 400) {
    return await response.json();
  } else {
    let errorResponse = await response.json();
    throw new Error(errorResponse.error);
  }
}

//Institution Type create

export async function createInstitutionType(countryId, body) {
  const token = localStorage.getItem(`token`);
  // console.log(body);
  try {
    const response = await fetch(`${URL}/institute/create/${countryId}`, {
      method: `post`,
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
      body: body,
    });
    // console.log(`addSection-Response`, response);
    return await response.json();
  } catch (e) {
    throw e;
  }
}

//Institution type Update

export async function updateInstitutionType(countryId, body) {
  try {
    const token = localStorage.getItem(`token`);
    const response = await fetch(`${URL}/institute/update/${countryId}`, {
      method: `PUT`,
      headers: {
        "Content-Type": "application/json",
      },
      Authorization: token,
      body: JSON.stringify(body),
    });
    if (response.status === 200) {
      return await response.json();
    } else if (response.status === 401 || response.status === 400) {
      return await response.json();
    } else {
      var errorResponse = await response.json();
      throw new Error(errorResponse.error);
    }
  } catch (error) {}
}

//Add User

export async function createData(body) {
  const token = localStorage.getItem(`token`);
  // console.log(body);
  try {
    const response = await fetch(`${URL}/booktype/add`, {
      method: `post`,
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
      body: body,
    });
    // console.log(`addSection-Response`, response);
    return await response.json();
  } catch (e) {
    throw e;
  }
}

export async function getFormType(query) {
  const token = localStorage.getItem(`token`);
  // let querys = query || "";
  const response = await fetch(`${URL}/booktypes`, {
    method: `get`,
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
  });
  // console.log(`Response`, response);
  if (response.status === 200) {
    return await response.json();
  } else if (response.status === 401 || response.status === 400) {
    return await response.json();
  } else {
    let errorResponse = await response.json();
    throw new Error(errorResponse.error);
  }
}

//User Type

export async function getUserTypeByAdmin(companyId) {
  const token = localStorage.getItem("token");
  const response = await fetch(`${URL}/usertype/admin/get/${companyId}`, {
    method: `get`,
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
  });
  // console.log(`Response`, response);
  if (response.status === 200) {
    return await response.json();
  } else if (response.status === 401 || response.status === 400) {
    return await response.json();
  } else {
    let errorResponse = await response.json();
    throw new Error(errorResponse.error);
  }
}

export async function createLoginPage(body) {
  const token = localStorage.getItem("token");
  // console.log(body);
  try {
    const response = await fetch(`${URL}/admin/login`, {
      method: `post`,
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
      body: body,
    });
    // console.log(`addSection-Response`, response);
    return await response.json();
  } catch (e) {
    throw e;
  }
}

export async function createContentUser(body) {
  const token = localStorage.getItem(`token`);
  // console.log(body);
  try {
    const response = await fetch(`${URL}/admin/content/user/add`, {
      method: `post`,
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
      body: body,
    });
    // console.log(`addSection-Response`, response);
    return await response.json();
  } catch (e) {
    throw e;
  }
}

export async function userLogin(body) {
  // console.log(body);
  try {
    const response = await fetch(`${URL}/user/login`, {
      method: `post`,
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
      body: body,
    });
    // console.log(`addSection-Response`, response);
    return await response.json();
  } catch (e) {
    throw e;
  }
}

export async function getAllUserByAdmin() {
  const token = localStorage.getItem("token");
  const response = await fetch(`${URL}/admin/content/user/getAll`, {
    method: `GET`,
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
  });
  // console.log(`Response`, response);
  if (response.status === 200) {
    return await response.json();
  } else if (response.status === 401 || response.status === 400) {
    return await response.json();
  } else {
    let errorResponse = await response.json();
    throw new Error(errorResponse.error);
  }
}

export async function getAllActiveUserByAdmin() {
  const token = localStorage.getItem("token");
  const response = await fetch(`${URL}/admin/active/content/user/getAll`, {
    method: `GET`,
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
  });
  // console.log(`Response`, response);
  if (response.status === 200) {
    return await response.json();
  } else if (response.status === 401 || response.status === 400) {
    return await response.json();
  } else {
    let errorResponse = await response.json();
    throw new Error(errorResponse.error);
  }
}

export async function getUserProfile(token) {
  const response = await fetch(`${URL}/user/profile`, {
    method: `GET`,
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
  });
  // console.log(`Response`, response);
  if (response.status === 200) {
    return await response.json();
  } else if (response.status === 401 || response.status === 400) {
    return await response.json();
  } else {
    let errorResponse = await response.json();
    throw new Error(errorResponse.error);
  }
}
export async function updateBlocked(userId) {
  const token = localStorage.getItem(`token`);
  try {
    const response = await fetch(`${URL}/admin/content/user/block/${userId}`, {
      method: `PUT`,
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    });
    if (response.status === 200) {
      return await response.json();
    } else if (response.status === 401 || response.status === 400) {
      return await response.json();
    } else {
      var errorResponse = await response.json();
      throw new Error(errorResponse.error);
    }
  } catch (e) {
    throw e;
  }
}

export async function updateMarked(userId) {
  const token = localStorage.getItem(`token`);
  try {
    const response = await fetch(`${URL}/admin/content/user/enable/${userId}`, {
      method: `PUT`,
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    });
    if (response.status === 200) {
      return await response.json();
    } else if (response.status === 401 || response.status === 400) {
      return await response.json();
    } else {
      var errorResponse = await response.json();
      throw new Error(errorResponse.error);
    }
  } catch (e) {
    throw e;
  }
}

export async function getBookBySession(sessionId, username, password) {
  // const token = localStorage.getItem(`token`);
  try {
    const response = await fetch(
      `${URL}/kampus/book/view/${sessionId}/${username}/${password}`,
      {
        method: `GET`,
        headers: {
          "Content-Type": "application/json",
          // Authorization: token,
        },
      }
    );
    if (response.status === 200) {
      return await response.json();
    } else if (response.status === 401 || response.status === 400) {
      return await response.json();
    } else {
      var errorResponse = await response.json();
      throw new Error(errorResponse.error);
    }
  } catch (e) {
    throw e;
  }
}

//Kampus Login API

export async function KampusLogin(body) {
  // const token = localStorage.getItem(`token`);
  try {
    const response = await fetch(
      // `https://demoapi.ueducate.in/login/checklogin`,
      `${kampusUrl}login/checklogin`,
      {
        method: `POST`,
        headers: {
          "Content-Type": "application/json",
          // Authorization: token,
        },
        body: JSON.stringify(body),
      }
    );
    return await response.json();
  } catch (e) {
    throw e;
  }
}

export async function getAllKatBook(body) {
  const response = await fetch(`${URL}/kampus/get/book`, {
    method: `POST`,
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(body),
  });
  // console.log(`Response`, response);
  if (response.status === 200) {
    return await response.json();
  } else if (response.status === 401 || response.status === 400) {
    return await response.json();
  } else {
    let errorResponse = await response.json();
    throw new Error(errorResponse.error);
  }
}

export async function getAllKatBookSubjects(body) {
  const response = await fetch(`${URL}/kampus/get/book1`, {
    method: `POST`,
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(body),
  });
  // console.log(`Response`, response);
  if (response.status === 200) {
    return await response.json();
  } else if (response.status === 401 || response.status === 400) {
    return await response.json();
  } else {
    let errorResponse = await response.json();
    throw new Error(errorResponse.error);
  }
}

export const getClarifications = async (body) => {
  // const token = localStorage.getItem(`token`);
  try {
    const response = await fetch(
      `https://demoapi.ueducate.in/collabration/fetchComments`,
      {
        method: `POST`,
        headers: {
          "Content-Type": "application/json",
          // Authorization: token,
        },
        body: JSON.stringify(body),
      }
    );
    return await response.json();
  } catch (e) {
    throw e;
  }
};

export const getAllParentMenu = async () => {
  const token = localStorage.getItem(`token`);
  try {
    const response = await fetch(`${URL}/menu/parentmenu/getAll`, {
      method: `GET`,
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    });
    return await response.json();
  } catch (e) {
    throw e;
  }
};

export const getAllParentSubMenu = async () => {
  const token = localStorage.getItem(`token`);
  try {
    const response = await fetch(`${URL}/menu/parent/submenu/getAll`, {
      method: `GET`,
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    });
    return await response.json();
  } catch (e) {
    throw e;
  }
};

export const getAllSubMenuAction = async () => {
  const token = localStorage.getItem(`token`);
  try {
    const response = await fetch(`${URL}/actiontype/getAll`, {
      method: `GET`,
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    });
    return await response.json();
  } catch (e) {
    throw e;
  }
};

export const getAllBookbyStudent = async (body) => {
  // const token = localStorage.getItem(`token`);
  try {
    const response = await fetch(
      // `https://demoapi.ueducate.in/collabration/fetchSubjects`,
      `${kampusUrl}collabration/dynamicFetchSubjects`,
      {
        method: `POST`,
        headers: {
          "Content-Type": "application/json",
          // Authorization: token,
        },
        body: JSON.stringify(body),
      }
    );
    return await response.json();
  } catch (e) {
    throw e;
  }
};

export const getKampusStudentLogin = async () => {
  // const token = localStorage.getItem(`token`);
  try {
    const response = await fetch(`${URL}/kampus/get/login`, {
      method: `GET`,
      headers: {
        "Content-Type": "application/json",
        // Authorization: token,
      },
    });
    return await response.json();
  } catch (e) {
    throw e;
  }
};

export const deleteKampusStudentLogin = async () => {
  try {
    const response = await fetch(`${URL}/kampus/delete/login`, {
      method: `DELETE`,
      headers: {
        "Content-Type": "application/json",
        // Authorization: token,
      },
    });
    return await response.json();
  } catch (e) {
    throw e;
  }
};

//dgewg;jlsdhgkjehtkp
export const addChatWithUploads = async (body, attach) => {
  const data = new FormData();
  Object.keys(body).map(async (e, i) => {
    await data.append([e], body[e]);
    return data;
  });
  if (Object.keys(attach).length > 0) {
    // console.log(attach, "attachment123");

    Object.keys(attach).map(async (e, i) => {
      // console.log(attach[e][0], "attachment123");
      await data.append("attachment", attach[e][0]);
      return data;
    });
  }

  // console.log("API12314", data);
  // const token = localStorage.getItem("token");
  try {
    const config = {
      method: "post",
      url: "https://demoapi.ueducate.in/attachment/upload",
      // url: "http://192.168.2.209:8080/KampusWebService/attachment/upload",
      headers: {
        "content-type": "multipart/form-data",
      },
      data: data,
    };
    const response = await axios(config);
    // console.log(response);
    if (response.status === 200) {
      return await response.data;
    } else if (response.status === 401 || response.status === 400) {
      return await response.data;
    } else {
      var errorResponse = await response.json();
      throw new Error(errorResponse.error);
    }
  } catch (e) {
    throw e;
  }
};

export const createChat = async (body) => {
  try {
    const response = await fetch(
      `https://demoapi.ueducate.in/collabration/addClarification`,
      {
        method: `post`,
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
        body: JSON.stringify(body),
      }
    );
    // console.log(`addSection-Response`, response);
    if (response.status === 200) {
      return await response.json();
    } else if (response.status === 401 || response.status === 400) {
      return await response.json();
    } else {
      var errorResponse = await response.json();
      throw new Error(errorResponse.error);
    }
  } catch (e) {
    throw e;
  }
};

// export const updateChat = async (body) => {
//   try {
//     const response = await fetch(
//       `https://demoapi.ueducate.in/collabration/updateComments`,
//       // `http://192.168.2.209:8080/KampusWebService/collabration/updateComments`,

//       {
//         method: `post`,
//         headers: {
//           "Content-Type": "application/json",
//           Authorization: token,
//         },
//         body: JSON.stringify(body),
//       }
//     );
//     // console.log(`addSection-Response`, response);
//     if (response.status === 200) {
//       return await response.json();
//     } else if (response.status === 401 || response.status === 400) {
//       return await response.json();
//     } else {
//       var errorResponse = await response.json();
//       throw new Error(errorResponse.error);
//     }
//   } catch (e) {
//     throw e;
//   }
// };

export const updateChat = async (body, attach) => {
  const data = new FormData();
  Object.keys(body).map(async (e, i) => {
    await data.append([e], body[e]);
    return data;
  });
  if (Object.keys(attach).length > 0) {
    // console.log(attach, "attachment123");

    Object.keys(attach).map(async (e, i) => {
      console.log(attach[e][0], "attachment123");
      await data.append("attachment", attach[e][0]);
      return data;
    });
  }

  console.log("API12314", data);
  // const token = localStorage.getItem("token");
  try {
    const config = {
      method: "post",
      // url: "http://192.168.3.107:8080/attachment/uploadUpdate",
      // url: "http://192.168.2.209:8080/KampusWebService/attachment/uploadUpdate",
      url: "https://demoapi.ueducate.in/attachment/uploadUpdate",
      headers: {
        "content-type": "multipart/form-data",
      },
      data: data,
    };
    const response = await axios(config);
    console.log(response);
    if (response.status === 200) {
      return await response.data;
    } else if (response.status === 401 || response.status === 400) {
      return await response.data;
    } else {
      var errorResponse = await response.json();
      throw new Error(errorResponse.error);
    }
  } catch (e) {
    throw e;
  }
};

export const deleteChat = async (body) => {
  try {
    const response = await fetch(
      `https://demoapi.ueducate.in/collabration/deleteComment`,
      {
        method: `post`,
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
        body: JSON.stringify(body),
      }
    );
    // console.log(`addSection-Response`, response);
    if (response.status === 200) {
      return await response.json();
    } else if (response.status === 401 || response.status === 400) {
      return await response.json();
    } else {
      var errorResponse = await response.json();
      throw new Error(errorResponse.error);
    }
  } catch (e) {
    throw e;
  }
};

//active key

export async function createKeyCount(body) {
  // console.log(body, "dhshdhsg");
  try {
    const token = localStorage.getItem("token");
    const response = await fetch(`${URL}/institution/key/create`, {
      method: `POST`,
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
      body: JSON.stringify(body),
    });
    if (response.status === 200) {
      return await response.json();
    } else if (response.status === 401 || response.status === 400) {
      return await response.json();
    } else {
      var errorResponse = await response.json();
      throw new Error(errorResponse.error);
    }
  } catch (e) {
    throw e;
  }
}

//get active key

export const getInstitutionKey = async (companyId, typeOfBookId, countryId) => {
  const token = localStorage.getItem(`token`);
  let response;
  try {
    response = await fetch(
      `${URL}/institution/key/get/all/${companyId}/${typeOfBookId}/${countryId}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      }
    );
    if (response.status === 200) {
      return await response.json();
    } else if (response.status === 401 || response.status === 400) {
      return await response.json();
    } else {
      var errorResponse = await response.json();
      throw new Error(errorResponse.error);
    }
  } catch (e) {
    throw e;
  }
};

//update key

export async function updateKey(
  companyId,
  countryId,
  institutionTypeId,
  typeOfBookId,
  body
) {
  try {
    const token = localStorage.getItem(`token`);
    const response = await fetch(
      `${URL}/institution/key/update/${companyId}/${typeOfBookId}/${countryId}/${institutionTypeId}`,
      {
        method: `PUT`,
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
        body: JSON.stringify(body),
      }
    );
    if (response.status === 200) {
      return await response.json();
    } else if (response.status === 401 || response.status === 400) {
      return await response.json();
    } else {
      var errorResponse = await response.json();
      throw new Error(errorResponse.error);
    }
  } catch (error) {}
}

//update marked key

export async function updateMarkedKey(userId) {
  const token = localStorage.getItem(`token`);
  try {
    const response = await fetch(`${URL}/admin/content/user/enable/${userId}`, {
      method: `PUT`,
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    });
    if (response.status === 200) {
      return await response.json();
    } else if (response.status === 401 || response.status === 400) {
      return await response.json();
    } else {
      var errorResponse = await response.json();
      throw new Error(errorResponse.error);
    }
  } catch (e) {
    throw e;
  }
}

// update blocked key

export async function updateBlockedKey(
  companyId,
  typeOfBookId,
  countryId,
  institutionTypeId
) {
  const token = localStorage.getItem(`token`);
  try {
    const response = await fetch(
      `${URL}/institution/block/${companyId}/${typeOfBookId}/${countryId}/${institutionTypeId}`,
      {
        method: `PUT`,
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      }
    );
    if (response.status === 200) {
      return await response.json();
    } else if (response.status === 401 || response.status === 400) {
      return await response.json();
    } else {
      var errorResponse = await response.json();
      throw new Error(errorResponse.error);
    }
  } catch (e) {
    throw e;
  }
}

export async function updateUnBlockedKey(
  companyId,
  typeOfBookId,
  countryId,
  institutionTypeId
) {
  const token = localStorage.getItem(`token`);
  try {
    const response = await fetch(
      `${URL}/institution/unblock/${companyId}/${typeOfBookId}/${countryId}/${institutionTypeId}`,
      {
        method: `PUT`,
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      }
    );
    if (response.status === 200) {
      return await response.json();
    } else if (response.status === 401 || response.status === 400) {
      return await response.json();
    } else {
      var errorResponse = await response.json();
      throw new Error(errorResponse.error);
    }
  } catch (e) {
    throw e;
  }
}

export async function updateUserPassword(userId, body) {
  const token = localStorage.getItem(`token`);
  try {
    const response = await fetch(`${URL}/admin/content/user/update/${userId}`, {
      method: `PUT`,
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
      body: JSON.stringify(body),
    });
    if (response.status === 200) {
      return await response.json();
    } else if (response.status === 401 || response.status === 400) {
      return await response.json();
    } else {
      var errorResponse = await response.json();
      throw new Error(errorResponse.error);
    }
  } catch (e) {
    throw e;
  }
}

// Assessments

// https://demoapi.ueducate.in/collabration/submitAssessment

export const createAssessments = async (body) => {
  try {
    const response = await fetch(
      // `https://demoapi.ueducate.in/collabration/submitAssessment`,
      `${kampusUrl}collabration/submitAssessment`,
      {
        method: `post`,
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
        body: JSON.stringify(body),
      }
    );
    // console.log(`addSection-Response`, response);
    if (response.status === 200) {
      return await response.json();
    } else if (response.status === 401 || response.status === 400) {
      return await response.json();
    } else {
      var errorResponse = await response.json();
      throw new Error(errorResponse.error);
    }
  } catch (e) {
    throw e;
  }
};

export async function getAllAssessmentByStudentId(body) {
  try {
    const response = await fetch(
      // `https://demoapi.ueducate.in/collabration/getAllAssessment`,
      `${kampusUrl}collabration/getAllAssessment`,
      {
        method: `post`,
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
        body: JSON.stringify(body),
      }
    );
    // console.log(`addSection-Response`, response);
    if (response.status === 200) {
      return await response.json();
    } else if (response.status === 401 || response.status === 400) {
      return await response.json();
    } else {
      var errorResponse = await response.json();
      throw new Error(errorResponse.error);
    }
  } catch (e) {
    throw e;
  }
}

export const googletts = async (body) => {
  try {
    const response = await fetch(
      `https://texttospeech.googleapis.com/v1beta1/text:synthesize?key=AIzaSyAOfqP2ieubdKvFhZwKt9-E6G9s-VobzbA`,
      {
        method: `post`,
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
        body: JSON.stringify(body),
      }
    );
    // console.log(`addSection-Response`, response);
    if (response.status === 200) {
      return await response.json();
    } else if (response.status === 401 || response.status === 400) {
      return await response.json();
    } else {
      var errorResponse = await response.json();
      throw new Error(errorResponse.error);
    }
  } catch (e) {
    throw e;
  }
};

// NOTES

// https://demoapi.ueducate.in/notes/addNotes

export const createNote = async (body) => {
  try {
    const response = await fetch(`https://demoapi.ueducate.in/notes/addNotes`, {
      method: `post`,
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
      body: JSON.stringify(body),
    });
    // console.log(`addSection-Response`, response);
    if (response.status === 200) {
      return await response.json();
    } else if (response.status === 401 || response.status === 400) {
      return await response.json();
    } else {
      var errorResponse = await response.json();
      throw new Error(errorResponse.error);
    }
  } catch (e) {
    throw e;
  }
};

export const getAllNotes = async (body) => {
  try {
    const response = await fetch(
      `https://demoapi.ueducate.in/notes/getAllNotes`,
      {
        method: `post`,
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
        body: JSON.stringify(body),
      }
    );
    // console.log(`addSection-Response`, response);
    if (response.status === 200) {
      return await response.json();
    } else if (response.status === 401 || response.status === 400) {
      return await response.json();
    } else {
      var errorResponse = await response.json();
      throw new Error(errorResponse.error);
    }
  } catch (e) {
    throw e;
  }
};

export const getNoteInfo = async (body) => {
  try {
    const response = await fetch(
      `https://demoapi.ueducate.in/notes/getNotesByNoteId`,
      {
        method: `post`,
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
        body: JSON.stringify(body),
      }
    );
    // console.log(`addSection-Response`, response);
    if (response.status === 200) {
      return await response.json();
    } else if (response.status === 401 || response.status === 400) {
      return await response.json();
    } else {
      var errorResponse = await response.json();
      throw new Error(errorResponse.error);
    }
  } catch (e) {
    throw e;
  }
};

export const updateNote = async (body) => {
  try {
    const response = await fetch(
      `https://demoapi.ueducate.in/notes/updateNotes`,
      {
        method: `post`,
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
        body: JSON.stringify(body),
      }
    );
    // console.log(`addSection-Response`, response);
    if (response.status === 200) {
      return await response.json();
    } else if (response.status === 401 || response.status === 400) {
      return await response.json();
    } else {
      var errorResponse = await response.json();
      throw new Error(errorResponse.error);
    }
  } catch (e) {
    throw e;
  }
};

export const deleteNote = async (body) => {
  try {
    const response = await fetch(
      `https://demoapi.ueducate.in/notes/deleteNotes`,
      {
        method: `post`,
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
        body: JSON.stringify(body),
      }
    );
    // console.log(`addSection-Response`, response);
    if (response.status === 200) {
      return await response.json();
    } else if (response.status === 401 || response.status === 400) {
      return await response.json();
    } else {
      var errorResponse = await response.json();
      throw new Error(errorResponse.error);
    }
  } catch (e) {
    throw e;
  }
};

export const searchNote = async (body) => {
  try {
    const response = await fetch(
      `https://demoapi.ueducate.in/notes/searchNotes`,
      {
        method: `post`,
        headers: {
          "Content-Type": "application/json",
          // Authorization: token,
        },
        body: JSON.stringify(body),
      }
    );
    if (response.status === 200) {
      return await response.json();
    } else if (response.status === 401 || response.status === 400) {
      return await response.json();
    } else {
      var errorResponse = await response.json();
      throw new Error(errorResponse.error);
    }
  } catch (e) {
    throw e;
  }
};

export async function getAllLog() {
  const token = localStorage.getItem(`token`);
  try {
    const response = await fetch(`${URL}/user/log/get/all`, {
      method: `GET`,
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    });
    if (response.status === 200) {
      return await response.json();
    } else if (response.status === 401 || response.status === 400) {
      return await response.json();
    } else {
      var errorResponse = await response.json();
      throw new Error(errorResponse.error);
    }
  } catch (e) {
    throw e;
  }
}

export const createBookMark = async (body) => {
  try {
    const response = await fetch(
      `https://demoapi.ueducate.in/bookmark/addBookmark`,
      {
        method: `post`,
        headers: {
          "Content-Type": "application/json",
          // Authorization: token,
        },
        body: JSON.stringify(body),
      }
    );
    if (response.status === 200) {
      return await response.json();
    } else if (response.status === 401 || response.status === 400) {
      return await response.json();
    } else {
      var errorResponse = await response.json();
      throw new Error(errorResponse.error);
    }
  } catch (e) {
    throw e;
  }
};

export const getAllBookMarks = async (body) => {
  try {
    const response = await fetch(
      `https://demoapi.ueducate.in/bookmark/getAllBookmarks`,
      {
        method: `post`,
        headers: {
          "Content-Type": "application/json",
          // Authorization: token,
        },
        body: JSON.stringify(body),
      }
    );
    if (response.status === 200) {
      return await response.json();
    } else if (response.status === 401 || response.status === 400) {
      return await response.json();
    } else {
      var errorResponse = await response.json();
      throw new Error(errorResponse.error);
    }
  } catch (e) {
    throw e;
  }
};

export const getBookMarkInfo = async (body) => {
  try {
    const response = await fetch(
      `https://demoapi.ueducate.in/bookmark/getBookmarksByBookmarkId`,
      {
        method: `post`,
        headers: {
          "Content-Type": "application/json",
          // Authorization: token,
        },
        body: JSON.stringify(body),
      }
    );
    if (response.status === 200) {
      return await response.json();
    } else if (response.status === 401 || response.status === 400) {
      return await response.json();
    } else {
      var errorResponse = await response.json();
      throw new Error(errorResponse.error);
    }
  } catch (e) {
    throw e;
  }
};

export const updateBookMark = async (body) => {
  try {
    const response = await fetch(
      `https://demoapi.ueducate.in/bookmark/updateBookmarks`,
      {
        method: `post`,
        headers: {
          "Content-Type": "application/json",
          // Authorization: token,
        },
        body: JSON.stringify(body),
      }
    );
    if (response.status === 200) {
      return await response.json();
    } else if (response.status === 401 || response.status === 400) {
      return await response.json();
    } else {
      var errorResponse = await response.json();
      throw new Error(errorResponse.error);
    }
  } catch (e) {
    throw e;
  }
};

export const deleteBookMark = async (body) => {
  try {
    const response = await fetch(
      `https://demoapi.ueducate.in/bookmark/deleteBookmark`,
      {
        method: `post`,
        headers: {
          "Content-Type": "application/json",
          // Authorization: token,
        },
        body: JSON.stringify(body),
      }
    );
    if (response.status === 200) {
      return await response.json();
    } else if (response.status === 401 || response.status === 400) {
      return await response.json();
    } else {
      var errorResponse = await response.json();
      throw new Error(errorResponse.error);
    }
  } catch (e) {
    throw e;
  }
};

export const searchBookMark = async (body) => {
  try {
    const response = await fetch(
      `https://demoapi.ueducate.in/bookmark/searchBookmark`,
      {
        method: `post`,
        headers: {
          "Content-Type": "application/json",
          // Authorization: token,
        },
        body: JSON.stringify(body),
      }
    );
    if (response.status === 200) {
      return await response.json();
    } else if (response.status === 401 || response.status === 400) {
      return await response.json();
    } else {
      var errorResponse = await response.json();
      throw new Error(errorResponse.error);
    }
  } catch (e) {
    throw e;
  }
};

export const createHL = async (body) => {
  try {
    const response = await fetch(
      `https://demoapi.ueducate.in/highlighter/addHighlighter`,
      {
        method: `post`,
        headers: {
          "Content-Type": "application/json",
          // Authorization: token,
        },
        body: JSON.stringify(body),
      }
    );
    if (response.status === 200) {
      return await response.json();
    } else if (response.status === 401 || response.status === 400) {
      return await response.json();
    } else {
      var errorResponse = await response.json();
      throw new Error(errorResponse.error);
    }
  } catch (e) {
    throw e;
  }
};

export const fetchAllHL = async (body) => {
  try {
    const response = await fetch(
      `https://demoapi.ueducate.in/highlighter/getAllHighlighters`,
      {
        method: `post`,
        headers: {
          "Content-Type": "application/json",
          // Authorization: token,
        },
        body: JSON.stringify(body),
      }
    );
    if (response.status === 200) {
      return await response.json();
    } else if (response.status === 401 || response.status === 400) {
      return await response.json();
    } else {
      var errorResponse = await response.json();
      throw new Error(errorResponse.error);
    }
  } catch (e) {
    throw e;
  }
};

export const fetchHLById = async (body) => {
  try {
    const response = await fetch(
      `https://demoapi.ueducate.in/highlighter/getHighlightersByHighlighterId`,
      {
        method: `post`,
        headers: {
          "Content-Type": "application/json",
          // Authorization: token,
        },
        body: JSON.stringify(body),
      }
    );
    if (response.status === 200) {
      return await response.json();
    } else if (response.status === 401 || response.status === 400) {
      return await response.json();
    } else {
      var errorResponse = await response.json();
      throw new Error(errorResponse.error);
    }
  } catch (e) {
    throw e;
  }
};

export const updateHL = async (body) => {
  try {
    const response = await fetch(
      `https://demoapi.ueducate.in/highlighter/updateHighlighters`,
      {
        method: `post`,
        headers: {
          "Content-Type": "application/json",
          // Authorization: token,
        },
        body: JSON.stringify(body),
      }
    );
    if (response.status === 200) {
      return await response.json();
    } else if (response.status === 401 || response.status === 400) {
      return await response.json();
    } else {
      var errorResponse = await response.json();
      throw new Error(errorResponse.error);
    }
  } catch (e) {
    throw e;
  }
};

export const deleteHL = async (body) => {
  try {
    const response = await fetch(
      `https://demoapi.ueducate.in/highlighter/deleteHighlighter`,
      {
        method: `post`,
        headers: {
          "Content-Type": "application/json",
          // Authorization: token,
        },
        body: JSON.stringify(body),
      }
    );
    if (response.status === 200) {
      return await response.json();
    } else if (response.status === 401 || response.status === 400) {
      return await response.json();
    } else {
      var errorResponse = await response.json();
      throw new Error(errorResponse.error);
    }
  } catch (e) {
    throw e;
  }
};

export const deleteInstitution = async (companyId) => {
  const token = localStorage.getItem("token");
  try {
    const response = await fetch(`${URL}/ias/institution/delete/${companyId}`, {
      method: `DELETE`,
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    });
    if (response.status === 200) {
      return await response.json();
    } else if (response.status === 401 || response.status === 400) {
      return await response.json();
    } else {
      var errorResponse = await response.json();
      throw new Error(errorResponse.error);
    }
  } catch (e) {
    throw e;
  }
};

export const unBlockInstitution = async (companyId) => {
  const token = localStorage.getItem("token");
  try {
    const response = await fetch(
      `${URL}/ias/institution/unblock/${companyId}`,
      {
        method: `PUT`,
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      }
    );
    if (response.status === 200) {
      return await response.json();
    } else if (response.status === 401 || response.status === 400) {
      return await response.json();
    } else {
      var errorResponse = await response.json();
      throw new Error(errorResponse.error);
    }
  } catch (e) {
    throw e;
  }
};

export const rechargePlanInstitution = async (body) => {
  const token = localStorage.getItem("token");
  try {
    const response = await fetch(`${URL}/ias/institution/recharge`, {
      method: `PUT`,
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
      body: JSON.stringify(body),
    });
    if (response.status === 200) {
      return await response.json();
    } else if (response.status === 401 || response.status === 400) {
      return await response.json();
    } else {
      var errorResponse = await response.json();
      throw new Error(errorResponse.error);
    }
  } catch (e) {
    throw e;
  }
};

export const getAllPlans = async (body) => {
  const token = localStorage.getItem("token");
  try {
    const response = await fetch(`${URL}/plan/get/all`, {
      method: `GET`,
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    });
    if (response.status === 200) {
      return await response.json();
    } else if (response.status === 401 || response.status === 400) {
      return await response.json();
    } else {
      var errorResponse = await response.json();
      throw new Error(errorResponse.error);
    }
  } catch (e) {
    throw e;
  }
};
export const createInstitutionAndRechargePlan = async (body) => {
  const token = localStorage.getItem("token");
  try {
    const response = await fetch(`${URL}/ias/institution/add`, {
      method: `POST`,
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
      body: JSON.stringify(body),
    });
    if (response.status === 200) {
      return await response.json();
    } else if (response.status === 401 || response.status === 400) {
      return await response.json();
    } else {
      var errorResponse = await response.json();
      throw new Error(errorResponse.error);
    }
  } catch (e) {
    throw e;
  }
};

export const blockInstitution = async (companyId) => {
  const token = localStorage.getItem("token");
  try {
    const response = await fetch(`${URL}/ias/institution/block/${companyId}`, {
      method: `PUT`,
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    });
    if (response.status === 200) {
      return await response.json();
    } else if (response.status === 401 || response.status === 400) {
      return await response.json();
    } else {
      var errorResponse = await response.json();
      throw new Error(errorResponse.error);
    }
  } catch (e) {
    throw e;
  }
};

export const getAllInstitutionPlan = async () => {
  const token = localStorage.getItem("token");
  try {
    const response = await fetch(`${URL}/ias/institution/get/all`, {
      method: `GET`,
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    });
    if (response.status === 200) {
      return await response.json();
    } else if (response.status === 401 || response.status === 400) {
      return await response.json();
    } else {
      var errorResponse = await response.json();
      throw new Error(errorResponse.error);
    }
  } catch (e) {
    throw e;
  }
};

export const getAllCompanies = async () => {
  const token = localStorage.getItem("token");
  try {
    const response = await fetch(`${URL}/companies`, {
      method: `GET`,
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    });
    if (response.status === 200) {
      return await response.json();
    } else if (response.status === 401 || response.status === 400) {
      return await response.json();
    } else {
      var errorResponse = await response.json();
      throw new Error(errorResponse.error);
    }
  } catch (e) {
    throw e;
  }
};

export const createInstitutionUserByAdmin = async (body) => {
  const token = localStorage.getItem("token");
  try {
    const response = await fetch(`${URL}/insitution/user/register`, {
      method: `POST`,
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
      body: JSON.stringify(body),
    });
    if (response.status === 200) {
      return await response.json();
    } else if (response.status === 401 || response.status === 400) {
      return await response.json();
    } else {
      var errorResponse = await response.json();
      throw new Error(errorResponse.error);
    }
  } catch (e) {
    throw e;
  }
};

export const getAllInstitutionUserByAdmin = async (companyId) => {
  const token = localStorage.getItem("token");
  try {
    const response = await fetch(
      `${URL}/institution/user/get/all/${companyId}`,
      {
        method: `GET`,
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      }
    );
    if (response.status === 200) {
      return await response.json();
    } else if (response.status === 401 || response.status === 400) {
      return await response.json();
    } else {
      var errorResponse = await response.json();
      throw new Error(errorResponse.error);
    }
  } catch (e) {
    throw e;
  }
};

export const blockInstitutionUserByAdmin = async (companyId, userId) => {
  const token = localStorage.getItem("token");
  try {
    const response = await fetch(
      `${URL}/institution/user/block/${companyId}/${userId}`,
      {
        method: `PUT`,
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      }
    );
    if (response.status === 200) {
      return await response.json();
    } else if (response.status === 401 || response.status === 400) {
      return await response.json();
    } else {
      var errorResponse = await response.json();
      throw new Error(errorResponse.error);
    }
  } catch (e) {
    throw e;
  }
};

export const unBlockInstitutionUserByAdmin = async (companyId, userId) => {
  const token = localStorage.getItem("token");
  try {
    const response = await fetch(
      `${URL}/institution/user/unblock/${companyId}/${userId}`,
      {
        method: `PUT`,
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      }
    );
    if (response.status === 200) {
      return await response.json();
    } else if (response.status === 401 || response.status === 400) {
      return await response.json();
    } else {
      var errorResponse = await response.json();
      throw new Error(errorResponse.error);
    }
  } catch (e) {
    throw e;
  }
};

export const resetPasswordInstitutionUserByAdmin = async (body) => {
  const token = localStorage.getItem("token");
  try {
    const response = await fetch(`${URL}/institution/user/password/reset`, {
      method: `PUT`,
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
      body: JSON.stringify(body),
    });
    if (response.status === 200) {
      return await response.json();
    } else if (response.status === 401 || response.status === 400) {
      return await response.json();
    } else {
      var errorResponse = await response.json();
      throw new Error(errorResponse.error);
    }
  } catch (e) {
    throw e;
  }
};

export const deleteInstitutionUserByAdmin = async (companyId, userId) => {
  const token = localStorage.getItem("token");
  try {
    const response = await fetch(
      `${URL}/institution/user/delete/${companyId}/${userId}`,
      {
        method: `DELETE`,
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      }
    );
    if (response.status === 200) {
      return await response.json();
    } else if (response.status === 401 || response.status === 400) {
      return await response.json();
    } else {
      var errorResponse = await response.json();
      throw new Error(errorResponse.error);
    }
  } catch (e) {
    throw e;
  }
};

export const createPlanByAdmin = async (body) => {
  const token = localStorage.getItem("token");
  try {
    const response = await fetch(`${URL}/plan/add`, {
      method: `POST`,
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
      body: JSON.stringify(body),
    });
    if (response.status === 200) {
      return await response.json();
    } else if (response.status === 401 || response.status === 400) {
      return await response.json();
    } else {
      var errorResponse = await response.json();
      throw new Error(errorResponse.error);
    }
  } catch (e) {
    throw e;
  }
};

export const updatePlanDiscountByAdmin = async (body) => {
  const token = localStorage.getItem("token");
  try {
    const response = await fetch(`${URL}/plan/discount/update`, {
      method: `PUT`,
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
      body: JSON.stringify(body),
    });
    if (response.status === 200) {
      return await response.json();
    } else if (response.status === 401 || response.status === 400) {
      return await response.json();
    } else {
      var errorResponse = await response.json();
      throw new Error(errorResponse.error);
    }
  } catch (e) {
    throw e;
  }
};

export const blockPlanByAdmin = async (planId) => {
  const token = localStorage.getItem("token");
  try {
    const response = await fetch(`${URL}/plan/block/${planId}`, {
      method: `PUT`,
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    });
    if (response.status === 200) {
      return await response.json();
    } else if (response.status === 401 || response.status === 400) {
      return await response.json();
    } else {
      var errorResponse = await response.json();
      throw new Error(errorResponse.error);
    }
  } catch (e) {
    throw e;
  }
};

export const unBlockPlanByAdmin = async (planId) => {
  const token = localStorage.getItem("token");
  try {
    const response = await fetch(`${URL}/plan/unblock/${planId}`, {
      method: `PUT`,
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    });
    if (response.status === 200) {
      return await response.json();
    } else if (response.status === 401 || response.status === 400) {
      return await response.json();
    } else {
      var errorResponse = await response.json();
      throw new Error(errorResponse.error);
    }
  } catch (e) {
    throw e;
  }
};

export const deletePlanByAdmin = async (planId) => {
  const token = localStorage.getItem("token");
  try {
    const response = await fetch(`${URL}/plan/delete/${planId}`, {
      method: `DELETE`,
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    });
    if (response.status === 200) {
      return await response.json();
    } else if (response.status === 401 || response.status === 400) {
      return await response.json();
    } else {
      var errorResponse = await response.json();
      throw new Error(errorResponse.error);
    }
  } catch (e) {
    throw e;
  }
};

export async function getAllInstitutionUserLog() {
  const token = localStorage.getItem(`token`);
  try {
    const response = await fetch(`${URL}/institution/user/log/get/all`, {
      method: `GET`,
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    });
    if (response.status === 200) {
      return await response.json();
    } else if (response.status === 401 || response.status === 400) {
      return await response.json();
    } else {
      var errorResponse = await response.json();
      throw new Error(errorResponse.error);
    }
  } catch (e) {
    throw e;
  }
}

export async function InstitutionUserLogin(body) {
  // console.log(body);
  try {
    const response = await fetch(`${URL}/insitution/user/login`, {
      method: `post`,
      headers: {
        "Content-Type": "application/json",
      },
      body: body,
    });
    // console.log(`addSection-Response`, response);
    return await response.json();
  } catch (e) {
    throw e;
  }
}

export async function getInstitutionUserProfile() {
  const token = localStorage.getItem(`token`);
  const response = await fetch(`${URL}/insitution/user/profile`, {
    method: `GET`,
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
  });
  // console.log(`Response`, response);
  if (response.status === 200) {
    return await response.json();
  } else if (response.status === 401 || response.status === 400) {
    return await response.json();
  } else {
    let errorResponse = await response.json();
    throw new Error(errorResponse.error);
  }
}

export async function getContentDetailsByBookId(bookId) {
  const token = localStorage.getItem(`token`);
  const response = await fetch(`${URL}/content/book/details/${bookId}`, {
    method: `GET`,
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
  });
  // console.log(`Response`, response);
  if (response.status === 200) {
    return await response.json();
  } else if (response.status === 401 || response.status === 400) {
    return await response.json();
  } else {
    let errorResponse = await response.json();
    throw new Error(errorResponse.error);
  }
}

export async function getAllUserContentLog(userId) {
  const token = localStorage.getItem(`token`);
  try {
    const response = await fetch(`${URL}/user/content/get/${userId}`, {
      method: `GET`,
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    });
    if (response.status === 200) {
      return await response.json();
    } else if (response.status === 401 || response.status === 400) {
      return await response.json();
    } else {
      var errorResponse = await response.json();
      throw new Error(errorResponse.error);
    }
  } catch (e) {
    throw e;
  }
}

export async function getAllUserContentLinkLog(userId) {
  const token = localStorage.getItem(`token`);
  try {
    const response = await fetch(`${URL}/user/content/link/get/${userId}`, {
      method: `GET`,
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    });
    if (response.status === 200) {
      return await response.json();
    } else if (response.status === 401 || response.status === 400) {
      return await response.json();
    } else {
      var errorResponse = await response.json();
      throw new Error(errorResponse.error);
    }
  } catch (e) {
    throw e;
  }
}

export async function getAllUserContentLogByDate(body) {
  const token = localStorage.getItem(`token`);
  try {
    const response = await fetch(`${URL}/user/content/date/get`, {
      method: `POST`,
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
      body: JSON.stringify(body),
    });
    if (response.status === 200) {
      return await response.json();
    } else if (response.status === 401 || response.status === 400) {
      return await response.json();
    } else {
      var errorResponse = await response.json();
      throw new Error(errorResponse.error);
    }
  } catch (e) {
    throw e;
  }
}

export async function getAllUserContentLinkLogByDate(body) {
  const token = localStorage.getItem(`token`);
  try {
    const response = await fetch(`${URL}/user/content/link/date/get`, {
      method: `POST`,
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
      body: JSON.stringify(body),
    });
    if (response.status === 200) {
      return await response.json();
    } else if (response.status === 401 || response.status === 400) {
      return await response.json();
    } else {
      var errorResponse = await response.json();
      throw new Error(errorResponse.error);
    }
  } catch (e) {
    throw e;
  }
}

export async function getClassesforTeachers(body) {
  // const token = localStorage.getItem(`token`);
  try {
    const response = await fetch(
      `${kampusUrl}staffSubject/getAllotedStandardsForTeachers`,
      {
        method: `POST`,
        headers: {
          "Content-Type": "application/json",
          // Authorization: token,
        },
        body: JSON.stringify(body),
      }
    );
    if (response.status === 200) {
      return await response.json();
    } else if (response.status === 401 || response.status === 400) {
      return await response.json();
    } else {
      var errorResponse = await response.json();
      throw new Error(errorResponse.error);
    }
  } catch (e) {
    throw e;
  }
}

export async function getSectionsForTeachers(body) {
  // const token = localStorage.getItem(`token`);
  try {
    const response = await fetch(
      `${kampusUrl}staffSubject/getSectionsForAllotedStandards`,
      {
        method: `POST`,
        headers: {
          "Content-Type": "application/json",
          // Authorization: token,
        },
        body: JSON.stringify(body),
      }
    );
    if (response.status === 200) {
      return await response.json();
    } else if (response.status === 401 || response.status === 400) {
      return await response.json();
    } else {
      var errorResponse = await response.json();
      throw new Error(errorResponse.error);
    }
  } catch (e) {
    throw e;
  }
}

export async function getSubjectsForTeacher(body) {
  // const token = localStorage.getItem(`token`);
  try {
    const response = await fetch(
      `${kampusUrl}staffSubject/getBooksForParticularSection`,
      {
        method: `POST`,
        headers: {
          "Content-Type": "application/json",
          // Authorization: token,
        },
        body: JSON.stringify(body),
      }
    );
    if (response.status === 200) {
      return await response.json();
    } else if (response.status === 401 || response.status === 400) {
      return await response.json();
    } else {
      var errorResponse = await response.json();
      throw new Error(errorResponse.error);
    }
  } catch (e) {
    throw e;
  }
}
export async function GetBooksbySubjectIds(body) {
  // const token = localStorage.getItem(`token`);
  try {
    const response = await fetch(`${URL}/content/teacher/get/book`, {
      method: `POST`,
      headers: {
        "Content-Type": "application/json",
        // Authorization: token,
      },
      body: JSON.stringify(body),
    });
    if (response.status === 200) {
      return await response.json();
    } else if (response.status === 401 || response.status === 400) {
      return await response.json();
    } else {
      var errorResponse = await response.json();
      throw new Error(errorResponse.error);
    }
  } catch (e) {
    throw e;
  }
}

export const getStaffAlloacationListForPreperation = async (
  companyId,
  typeOfBook,
  countryId,
  institutionTypeId,
  contentDeveloperId,
  attributeId
) => {
  try {
    const token = localStorage.getItem(`token`);

    const response = await fetch(
      `${URL}/staff/content/perpation/get/${companyId}/${typeOfBook}/${countryId}/${institutionTypeId}/${attributeId}/${contentDeveloperId}`,
      {
        method: `GET`,
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      }
    );
    if (response.status === 200) {
      return await response.json();
    } else if (response.status === 401 || response.status === 400) {
      return await response.json();
    } else {
      var errorResponse = await response.json();
      throw new Error(errorResponse.error);
    }
  } catch (e) {
    throw e;
  }
};

export const AddStaffAllocationForPreperation = async (body) => {
  try {
    const token = localStorage.getItem(`token`);

    const response = await fetch(`${URL}/staff/content/prepation/create`, {
      method: `POST`,
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
      body: JSON.stringify(body),
    });
    if (response.status === 200) {
      return await response.json();
    } else if (response.status === 401 || response.status === 400) {
      return await response.json();
    } else {
      var errorResponse = await response.json();
      throw new Error(errorResponse.error);
    }
  } catch (e) {
    throw e;
  }
};

export const AddStaffAllocationForUploading = async (body) => {
  try {
    const token = localStorage.getItem(`token`);

    const response = await fetch(`${URL}/staff/content/uploading/create`, {
      method: `POST`,
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
      body: JSON.stringify(body),
    });
    if (response.status === 200) {
      return await response.json();
    } else if (response.status === 401 || response.status === 400) {
      return await response.json();
    } else {
      var errorResponse = await response.json();
      throw new Error(errorResponse.error);
    }
  } catch (e) {
    throw e;
  }
};

//content preparation

export async function createPreparation(body) {
  const token = localStorage.getItem(`token`);
  // console.log(body);
  try {
    const response = await fetch(`${URL}/content/prepation/name/create`, {
      method: `post`,
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
      body: body,
    });
    // console.log(`addSection-Response`, response);
    return await response.json();
  } catch (e) {
    throw e;
  }
}

export async function getAllPrepActivity(companyId) {
  const token = localStorage.getItem("token");
  const response = await fetch(
    `${URL}/content/prepation/name/get/all/${companyId}`,
    {
      method: `GET`,
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    }
  );
  // console.log(`Response`, response);
  if (response.status === 200) {
    return await response.json();
  } else if (response.status === 401 || response.status === 400) {
    return await response.json();
  } else {
    let errorResponse = await response.json();
    throw new Error(errorResponse.error);
  }
}

export async function getAllPrepActivityByvariableId(variableId, developerId) {
  const token = localStorage.getItem("token");
  const response = await fetch(
    `${URL}/content/prepation/name/get/all/${variableId}/${developerId}`,
    {
      method: `GET`,
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    }
  );
  // console.log(`Response`, response);
  if (response.status === 200) {
    return await response.json();
  } else if (response.status === 401 || response.status === 400) {
    return await response.json();
  } else {
    let errorResponse = await response.json();
    throw new Error(errorResponse.error);
  }
}

export async function updatePreparation(body) {
  const token = localStorage.getItem(`token`);
  try {
    const response = await fetch(`${URL}/content/prepation/name/update`, {
      method: `PUT`,
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
      body: JSON.stringify(body),
    });
    if (response.status === 200) {
      return await response.json();
    } else if (response.status === 401 || response.status === 400) {
      return await response.json();
    } else {
      var errorResponse = await response.json();
      throw new Error(errorResponse.error);
    }
  } catch (e) {
    throw e;
  }
}

export async function deletePreparationActivity(
  companyId,
  contentPreparationNameId
) {
  let response;
  try {
    const token = localStorage.getItem(`token`);
    response = await fetch(
      `${URL}/content/prepation/name/delete/${companyId}/${contentPreparationNameId}
      `,
      {
        method: `delete`,
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      }
    );
    // console.log(`delete school response...`, response);
    if (response.status === 200) {
      return await response.json();
    } else if (response.status === 401 || response.status === 400) {
      return await response.json();
    } else {
      var errorResponse = await response.json();
      throw new Error(errorResponse.error);
    }
  } catch (e) {
    throw e;
  }
}

// content uploading

export async function createUploading(body) {
  const token = localStorage.getItem(`token`);
  // console.log(body);
  try {
    const response = await fetch(`${URL}/content/uploading/name/create`, {
      method: `post`,
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
      body: body,
    });
    // console.log(`addSection-Response`, response);
    return await response.json();
  } catch (e) {
    throw e;
  }
}

export async function getAllUploadingActivity(companyId) {
  const token = localStorage.getItem("token");
  const response = await fetch(
    `${URL}/content/uploading/name/getall/${companyId}`,
    {
      method: `GET`,
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    }
  );
  // console.log(`Response`, response);
  if (response.status === 200) {
    return await response.json();
  } else if (response.status === 401 || response.status === 400) {
    return await response.json();
  } else {
    let errorResponse = await response.json();
    throw new Error(errorResponse.error);
  }
}

export async function updateUploading(body) {
  const token = localStorage.getItem(`token`);
  try {
    const response = await fetch(`${URL}/content/uploading/name/update`, {
      method: `PUT`,
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
      body: JSON.stringify(body),
    });
    if (response.status === 200) {
      return await response.json();
    } else if (response.status === 401 || response.status === 400) {
      return await response.json();
    } else {
      var errorResponse = await response.json();
      throw new Error(errorResponse.error);
    }
  } catch (e) {
    throw e;
  }
}

export const getStaffAlloacationListForUploading = async (
  companyId,
  typeOfBook,
  countryId,
  institutionTypeId,
  contentDeveloperId,
  attributeId
) => {
  try {
    const token = localStorage.getItem(`token`);

    const response = await fetch(
      `${URL}/staff/content/uploading/get/${companyId}/${typeOfBook}/${countryId}/${institutionTypeId}/${attributeId}/${contentDeveloperId}`,
      {
        method: `GET`,
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      }
    );
    if (response.status === 200) {
      return await response.json();
    } else if (response.status === 401 || response.status === 400) {
      return await response.json();
    } else {
      var errorResponse = await response.json();
      throw new Error(errorResponse.error);
    }
  } catch (e) {
    throw e;
  }
};

export const getAllContentUploadingNames = async (companyId) => {
  try {
    const token = localStorage.getItem(`token`);

    const response = await fetch(
      `${URL}/content/uploading/name/getall/${companyId}`,
      {
        method: `GET`,
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      }
    );
    if (response.status === 200) {
      return await response.json();
    } else if (response.status === 401 || response.status === 400) {
      return await response.json();
    } else {
      var errorResponse = await response.json();
      throw new Error(errorResponse.error);
    }
  } catch (e) {
    throw e;
  }
};

export const getAllActivityByvariableId = async (variableId, developerId) => {
  try {
    const token = localStorage.getItem(`token`);

    const response = await fetch(
      `${URL}/content/uploading/name/get/all/${variableId}/${developerId}`,
      {
        method: `GET`,
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      }
    );
    if (response.status === 200) {
      return await response.json();
    } else if (response.status === 401 || response.status === 400) {
      return await response.json();
    } else {
      var errorResponse = await response.json();
      throw new Error(errorResponse.error);
    }
  } catch (e) {
    throw e;
  }
};

export const getAllActivityByStatusReportuploadingvariableId = async (
  variableId
) => {
  try {
    const token = localStorage.getItem(`token`);

    const response = await fetch(
      `${URL}/content/uploading/name/get/${variableId}`,
      {
        method: `GET`,
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      }
    );
    if (response.status === 200) {
      return await response.json();
    } else if (response.status === 401 || response.status === 400) {
      return await response.json();
    } else {
      var errorResponse = await response.json();
      throw new Error(errorResponse.error);
    }
  } catch (e) {
    throw e;
  }
};

export const getAllActivityByStatusReportpreparationvariableId = async (
  variableId
) => {
  try {
    const token = localStorage.getItem(`token`);

    const response = await fetch(
      `${URL}/content/preparation/name/get/${variableId}`,
      {
        method: `GET`,
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      }
    );
    if (response.status === 200) {
      return await response.json();
    } else if (response.status === 401 || response.status === 400) {
      return await response.json();
    } else {
      var errorResponse = await response.json();
      throw new Error(errorResponse.error);
    }
  } catch (e) {
    throw e;
  }
};

export const getAllSubjectstatusreportUploading = async (variableId) => {
  try {
    const token = localStorage.getItem(`token`);

    const response = await fetch(`${URL}/variables/get/subject/${variableId}`, {
      method: `GET`,
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    });
    if (response.status === 200) {
      return await response.json();
    } else if (response.status === 401 || response.status === 400) {
      return await response.json();
    } else {
      var errorResponse = await response.json();
      throw new Error(errorResponse.error);
    }
  } catch (e) {
    throw e;
  }
};

export async function deleteUploadingActivity(
  companyId,
  contentUploadingNameId
) {
  let response;
  try {
    const token = localStorage.getItem(`token`);
    response = await fetch(
      `${URL}/content/uploading/name/delete/${companyId}/${contentUploadingNameId}`,
      {
        method: `delete`,
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      }
    );
    // console.log(`delete school response...`, response);
    if (response.status === 200) {
      return await response.json();
    } else if (response.status === 401 || response.status === 400) {
      return await response.json();
    } else {
      var errorResponse = await response.json();
      throw new Error(errorResponse.error);
    }
  } catch (e) {
    throw e;
  }
}

export const getAllContentStaffTypeByTypeId = async (userTypeId) => {
  let response;
  try {
    const token = localStorage.getItem(`token`);
    response = await fetch(
      `${URL}/admin/content/user/getAll/type/${userTypeId}`,
      {
        method: `GET`,
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      }
    );
    // console.log(`delete school response...`, response);
    if (response.status === 200) {
      return await response.json();
    } else if (response.status === 401 || response.status === 400) {
      return await response.json();
    } else {
      var errorResponse = await response.json();
      throw new Error(errorResponse.error);
    }
  } catch (e) {
    throw e;
  }
};

export async function getAllPlaningFirstVariables(
  companyId,
  typeOfBook,
  countryId,
  institutionTypeId,
  attributeId,
  contentDeveloperId
  // variableId
) {
  const token = localStorage.getItem(`token`);

  try {
    const response = await fetch(
      `${URL}/staff/content/perpation/first/variable/get/${companyId}/${typeOfBook}/${countryId}/${institutionTypeId}/${attributeId}/${contentDeveloperId}`,
      {
        method: `GET`,
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      }
    );
    if (response.status === 200) {
      return await response.json();
    } else if (response.status === 401 || response.status === 400) {
      return await response.json();
    } else {
      var errorResponse = await response.json();
      throw new Error(errorResponse.error);
    }
  } catch (e) {
    throw e;
  }
}

export async function getAllPlaningVariables(
  companyId,
  typeOfBook,
  countryId,
  institutionTypeId,
  attributeId,
  contentDeveloperId,
  variableId
) {
  const token = localStorage.getItem(`token`);

  try {
    const response = await fetch(
      `${URL}/staff/content/perpation/variable/get/${companyId}/${typeOfBook}/${countryId}/${institutionTypeId}/${attributeId}/${contentDeveloperId}/${variableId}`,
      {
        method: `GET`,
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      }
    );
    if (response.status === 200) {
      return await response.json();
    } else if (response.status === 401 || response.status === 400) {
      return await response.json();
    } else {
      var errorResponse = await response.json();
      throw new Error(errorResponse.error);
    }
  } catch (e) {
    throw e;
  }
}

export const createPrepaActi = async (data) => {
  let response;
  const token = localStorage.getItem(`token`);
  try {
    response = await fetch(`${URL}/planning/content/preparation/create`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
      body: JSON.stringify({ data: data }),
    });
    if (response.status === 200) {
      return await response.json();
    } else if (response.status === 401 || response.status === 400) {
      return await response.json();
    } else {
      var errorResponse = await response.json();
      throw new Error(errorResponse.error);
    }
  } catch (e) {
    throw e;
  }
};

export async function getAllPreparationActivity(
  contentDeveloperId,
  variableId
) {
  const token = localStorage.getItem("token");
  const response = await fetch(
    `${URL}/planning/content/preparation/activites/get/${contentDeveloperId}/${variableId}`,
    {
      method: `GET`,
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    }
  );
  // console.log(`Response`, response);
  if (response.status === 200) {
    return await response.json();
  } else if (response.status === 401 || response.status === 400) {
    return await response.json();
  } else {
    let errorResponse = await response.json();
    throw new Error(errorResponse.error);
  }
}

export async function getViewPerContent(
  companyId,
  typeOfBook,
  countryId,
  institutionTypeId,
  attributeId,
  contentDeveloperId,
  variableId
) {
  const token = localStorage.getItem("token");
  const response = await fetch(
    `${URL}/planning/content/preparation/get/${companyId}/${typeOfBook}/${countryId}/${institutionTypeId}/${attributeId}/${contentDeveloperId}/${variableId}`,
    {
      method: `GET`,
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    }
  );
  // console.log(`Response`, response);
  if (response.status === 200) {
    return await response.json();
  } else if (response.status === 401 || response.status === 400) {
    return await response.json();
  } else {
    let errorResponse = await response.json();
    throw new Error(errorResponse.error);
  }
}

export async function updateResceduleList(body) {
  try {
    const token = localStorage.getItem(`token`);
    const response = await fetch(
      `${URL}/planning/content/preparation/reSechulding`,
      {
        method: `PUT`,
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
        body: JSON.stringify(body),
      }
    );
    if (response.status === 200) {
      return await response.json();
    } else if (response.status === 401 || response.status === 400) {
      return await response.json();
    } else {
      var errorResponse = await response.json();
      throw new Error(errorResponse.error);
    }
  } catch (error) {}
}

export async function getAllSeelectedPreparationActivity(
  contentDeveloperId,
  variableId,
  planningId
) {
  const token = localStorage.getItem("token");
  const response = await fetch(
    `${URL}/planning/content/preparation/selected/activites/get/${contentDeveloperId}/${variableId}/${planningId}`,
    {
      method: `GET`,
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    }
  );
  // console.log(`Response`, response);
  if (response.status === 200) {
    return await response.json();
  } else if (response.status === 401 || response.status === 400) {
    return await response.json();
  } else {
    let errorResponse = await response.json();
    throw new Error(errorResponse.error);
  }
}

export const reSechuldePreparationActivities = async (data) => {
  let response;
  const token = localStorage.getItem(`token`);
  try {
    response = await fetch(`${URL}/planning/content/preparation/reSechulding`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
      body: JSON.stringify(data),
    });
    if (response.status === 200) {
      return await response.json();
    } else if (response.status === 401 || response.status === 400) {
      return await response.json();
    } else {
      var errorResponse = await response.json();
      throw new Error(errorResponse.error);
    }
  } catch (e) {
    throw e;
  }
};

export const assignUploadingActivityPS = async (body) => {
  let response;
  try {
    const token = localStorage.getItem(`token`);
    response = await fetch(`${URL}/planning/content/uploadig/create`, {
      method: `POST`,
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
      body: JSON.stringify({ data: body }),
    });
    if (response.status === 200) {
      return await response.json();
    } else if (response.status === 401 || response.status === 400) {
      return await response.json();
    } else {
      var errorResponse = await response.json();
      throw new Error(errorResponse.error);
    }
  } catch (e) {
    throw e;
  }
};

export const getSessionPreparationActivitiesPSUpload = async (
  contentUploaderId,
  variableId
) => {
  let response;
  try {
    const token = localStorage.getItem(`token`);
    response = await fetch(
      `${URL}/planning/content/preparation/session/activites/get/${contentUploaderId}/${variableId}`,
      {
        method: `GET`,
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      }
    );
    // console.log(`delete school response...`, response);
    if (response.status === 200) {
      return await response.json();
    } else if (response.status === 401 || response.status === 400) {
      return await response.json();
    } else {
      var errorResponse = await response.json();
      throw new Error(errorResponse.error);
    }
  } catch (e) {
    throw e;
  }
};

export const getSessionActivitiesPSUpload = async (
  contentUploaderId,
  variableId
) => {
  let response;
  try {
    const token = localStorage.getItem(`token`);
    response = await fetch(
      `${URL}/planning/content/uploading/session/activites/get/${contentUploaderId}/${variableId}`,
      {
        method: `GET`,
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      }
    );
    // console.log(`delete school response...`, response);
    if (response.status === 200) {
      return await response.json();
    } else if (response.status === 401 || response.status === 400) {
      return await response.json();
    } else {
      var errorResponse = await response.json();
      throw new Error(errorResponse.error);
    }
  } catch (e) {
    throw e;
  }
};

export const getAllActivitiesPSUpload = async (
  contentUploaderId,
  variableId
) => {
  let response;
  try {
    const token = localStorage.getItem(`token`);
    response = await fetch(
      `${URL}/planning/content/uploading/activites/get/${contentUploaderId}/${variableId}`,
      {
        method: `GET`,
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      }
    );
    // console.log(`delete school response...`, response);
    if (response.status === 200) {
      return await response.json();
    } else if (response.status === 401 || response.status === 400) {
      return await response.json();
    } else {
      var errorResponse = await response.json();
      throw new Error(errorResponse.error);
    }
  } catch (e) {
    throw e;
  }
};

export const getAllStaffAllocatedUploadingFirstVariable = async (
  companyId,
  typeOfBook,
  countryId,
  institutionTypeId,
  attributeId,
  contentDeveloperId
) => {
  let response;
  try {
    const token = localStorage.getItem(`token`);
    response = await fetch(
      `${URL}/staff/content/uploading/first/variable/get/${companyId}/${typeOfBook}/${countryId}/${institutionTypeId}/${attributeId}/${contentDeveloperId}`,
      {
        method: `GET`,
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      }
    );
    // console.log(`delete school response...`, response);
    if (response.status === 200) {
      return await response.json();
    } else if (response.status === 401 || response.status === 400) {
      return await response.json();
    } else {
      var errorResponse = await response.json();
      throw new Error(errorResponse.error);
    }
  } catch (e) {
    throw e;
  }
};

export const getAllStaffUploadingVariables = async (
  companyId,
  typeOfBook,
  countryId,
  institutionTypeId,
  attributeId,
  contentDeveloperId,
  variableId
) => {
  let response;
  try {
    const token = localStorage.getItem(`token`);
    response = await fetch(
      `${URL}/staff/content/uploading/variable/get/${companyId}/${typeOfBook}/${countryId}/${institutionTypeId}/${attributeId}/${contentDeveloperId}/${variableId}`,
      {
        method: `GET`,
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      }
    );
    // console.log(`delete school response...`, response);
    if (response.status === 200) {
      return await response.json();
    } else if (response.status === 401 || response.status === 400) {
      return await response.json();
    } else {
      var errorResponse = await response.json();
      throw new Error(errorResponse.error);
    }
  } catch (e) {
    throw e;
  }
};

export const getAllplanningContentUploading = async (
  companyId,
  typeOfBook,
  countryId,
  institutionTypeId,
  attributeId,
  contentDeveloperId,
  variableId
) => {
  let response;
  try {
    const token = localStorage.getItem(`token`);
    response = await fetch(
      `${URL}/planning/content/uploading/get/${companyId}/${typeOfBook}/${countryId}/${institutionTypeId}/${attributeId}/${contentDeveloperId}/${variableId}`,
      {
        method: `GET`,
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      }
    );
    // console.log(`delete school response...`, response);
    if (response.status === 200) {
      return await response.json();
    } else if (response.status === 401 || response.status === 400) {
      return await response.json();
    } else {
      var errorResponse = await response.json();
      throw new Error(errorResponse.error);
    }
  } catch (e) {
    throw e;
  }
};

export const getallUserList = async () => {
  let response;
  try {
    const token = localStorage.getItem(`token`);
    response = await fetch(`${URL}/admin/active/content/user/getAll`, {
      method: `GET`,
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    });
    // console.log(`delete school response...`, response);
    if (response.status === 200) {
      return await response.json();
    } else if (response.status === 401 || response.status === 400) {
      return await response.json();
    } else {
      var errorResponse = await response.json();
      throw new Error(errorResponse.error);
    }
  } catch (e) {
    throw e;
  }
};

export const createUserPrivileges = async (body) => {
  let response;
  try {
    const token = localStorage.getItem(`token`);
    response = await fetch(`${URL}/user/menuprivileges/create`, {
      method: `POST`,
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
      body: JSON.stringify(body),
    });
    // console.log(`delete school response...`, response);
    if (response.status === 200) {
      return await response.json();
    } else if (response.status === 401 || response.status === 400) {
      return await response.json();
    } else {
      var errorResponse = await response.json();
      throw new Error(errorResponse.error);
    }
  } catch (e) {
    throw e;
  }
};

export const updateUserPrivileges = async (body) => {
  let response;
  try {
    const token = localStorage.getItem(`token`);
    response = await fetch(`${URL}/user/menuprivileges/update`, {
      method: `POST`,
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
      body: JSON.stringify(body),
    });
    // console.log(`delete school response...`, response);
    if (response.status === 200) {
      return await response.json();
    } else if (response.status === 401 || response.status === 400) {
      return await response.json();
    } else {
      var errorResponse = await response.json();
      throw new Error(errorResponse.error);
    }
  } catch (e) {
    throw e;
  }
};

export const getUserPrivileges = async (body) => {
  let response;
  try {
    const token = localStorage.getItem(`token`);
    response = await fetch(`${URL}/user/menuprivileges/byuserid`, {
      method: `POST`,
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
      body: JSON.stringify(body),
    });
    // console.log(`delete school response...`, response);
    if (response.status === 200) {
      return await response.json();
    } else if (response.status === 401 || response.status === 400) {
      return await response.json();
    } else {
      var errorResponse = await response.json();
      throw new Error(errorResponse.error);
    }
  } catch (e) {
    throw e;
  }
};

export const getContentStaffTypeByTypeId = async (userTypeId) => {
  let response;
  try {
    const token = localStorage.getItem(`token`);
    response = await fetch(`${URL}/user/content/user/get/type/${userTypeId}`, {
      method: `GET`,
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    });
    // console.log(`delete school response...`, response);
    if (response.status === 200) {
      return await response.json();
    } else if (response.status === 401 || response.status === 400) {
      return await response.json();
    } else {
      var errorResponse = await response.json();
      throw new Error(errorResponse.error);
    }
  } catch (e) {
    throw e;
  }
};

export const getAllContentStaffTypeByUserTypeId = async (companyId) => {
  let response;
  try {
    const token = localStorage.getItem(`token`);
    response = await fetch(`${URL}/user/userType/get/all/${companyId}`, {
      method: `GET`,
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    });
    // console.log(`delete school response...`, response);
    if (response.status === 200) {
      return await response.json();
    } else if (response.status === 401 || response.status === 400) {
      return await response.json();
    } else {
      var errorResponse = await response.json();
      throw new Error(errorResponse.error);
    }
  } catch (e) {
    throw e;
  }
};

export async function getViewPerContentByActivity(
  companyId,
  typeOfBook,
  countryId,
  institutionTypeId,
  attributeId,
  contentDeveloperId,
  variableId,
  activityId
) {
  const token = localStorage.getItem("token");
  const response = await fetch(
    `${URL}/planning/content/preparation/get/activity/${companyId}/${typeOfBook}/${countryId}/${institutionTypeId}/${attributeId}/${contentDeveloperId}/${variableId}`,
    {
      method: `PUT`,
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
      body: JSON.stringify({ activityId: activityId }),
    }
  );
  // console.log(`Response`, response);
  if (response.status === 200) {
    return await response.json();
  } else if (response.status === 401 || response.status === 400) {
    return await response.json();
  } else {
    let errorResponse = await response.json();
    throw new Error(errorResponse.error);
  }
}

export const getAllplanningContentUploadingByActivity = async (
  companyId,
  typeOfBook,
  countryId,
  institutionTypeId,
  attributeId,
  contentUploaderId,
  variableId,
  activityId
) => {
  try {
    const token = localStorage.getItem(`token`);
    const response = await fetch(
      `${URL}/planning/content/uploading/get/activity/${companyId}/${typeOfBook}/${countryId}/${institutionTypeId}/${attributeId}/${contentUploaderId}/${variableId}`,
      {
        method: `PUT`,
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
        body: JSON.stringify({ activityId: activityId }),
      }
    );
    // console.log(`delete school response...`, response);
    if (response.status === 200) {
      return await response.json();
    } else if (response.status === 401 || response.status === 400) {
      return await response.json();
    } else {
      var errorResponse = await response.json();
      throw new Error(errorResponse.error);
    }
  } catch (e) {
    throw e;
  }
};

export const reSechuldeUploadingActivities = async (data) => {
  let response;
  const token = localStorage.getItem(`token`);
  try {
    response = await fetch(`${URL}/planning/content/uploading/reSechulding`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
      body: JSON.stringify(data),
    });
    if (response.status === 200) {
      return await response.json();
    } else if (response.status === 401 || response.status === 400) {
      return await response.json();
    } else {
      var errorResponse = await response.json();
      throw new Error(errorResponse.error);
    }
  } catch (e) {
    throw e;
  }
};

export const updateHoliday = async (body) => {
  try {
    const token = localStorage.getItem(`token`);
    const response = await fetch(`${URL}/holiday/update`, {
      method: `PUT`,
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
      body: JSON.stringify(body),
    });
    // console.log(`delete school response...`, response);
    if (response.status === 200) {
      return await response.json();
    } else if (response.status === 401 || response.status === 400) {
      return await response.json();
    } else {
      var errorResponse = await response.json();
      throw new Error(errorResponse.error);
    }
  } catch (e) {
    throw e;
  }
};

export const getAllHolidays = async (companyId) => {
  try {
    const token = localStorage.getItem(`token`);
    const response = await fetch(`${URL}/holiday/get/all/${companyId}`, {
      method: `GET`,
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    });
    // console.log(`delete school response...`, response);
    if (response.status === 200) {
      return await response.json();
    } else if (response.status === 401 || response.status === 400) {
      return await response.json();
    } else {
      var errorResponse = await response.json();
      throw new Error(errorResponse.error);
    }
  } catch (e) {
    throw e;
  }
};

export const createHoliday = async (body) => {
  try {
    const token = localStorage.getItem(`token`);
    const response = await fetch(`${URL}/holiday/create`, {
      method: `POST`,
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
      body: JSON.stringify(body),
    });
    // console.log(`delete school response...`, response);
    if (response.status === 200) {
      return await response.json();
    } else if (response.status === 401 || response.status === 400) {
      return await response.json();
    } else {
      var errorResponse = await response.json();
      throw new Error(errorResponse.error);
    }
  } catch (e) {
    throw e;
  }
};

export const deleteHoliday = async (companyId, holidayId) => {
  try {
    const token = localStorage.getItem(`token`);
    const response = await fetch(
      `${URL}/holiday/delete/${companyId}/${holidayId}`,
      {
        method: `DELETE`,
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      }
    );
    // console.log(`delete school response...`, response);
    if (response.status === 200) {
      return await response.json();
    } else if (response.status === 401 || response.status === 400) {
      return await response.json();
    } else {
      var errorResponse = await response.json();
      throw new Error(errorResponse.error);
    }
  } catch (e) {
    throw e;
  }
};

export async function getAllInCompletedActivity(
  companyId,
  typeOfBook,
  countryId,
  institutionTypeId,
  attributeId
) {
  const token = localStorage.getItem("token");
  const response = await fetch(
    `${URL}/dairy/content/preparation/get/all/${companyId}/${typeOfBook}/${countryId}/${institutionTypeId}/${attributeId}`,
    {
      method: `GET`,
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    }
  );
  // console.log(`Response`, response);
  if (response.status === 200) {
    return await response.json();
  } else if (response.status === 401 || response.status === 400) {
    return await response.json();
  } else {
    let errorResponse = await response.json();
    throw new Error(errorResponse.error);
  }
}

export const updatePlanningPreparationActivitiesStatus = async (data) => {
  let response;
  const token = localStorage.getItem(`token`);
  try {
    response = await fetch(
      `${URL}/dairy/content/preparation/activity/status/update`,
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
        body: JSON.stringify({ data: data }),
      }
    );
    if (response.status === 200) {
      return await response.json();
    } else if (response.status === 401 || response.status === 400) {
      return await response.json();
    } else {
      var errorResponse = await response.json();
      throw new Error(errorResponse.error);
    }
  } catch (e) {
    throw e;
  }
};

export const updatePlanningUploadingActivitiesStatus = async (data) => {
  let response;
  const token = localStorage.getItem(`token`);
  try {
    response = await fetch(
      `${URL}/dairy/content/uploading/activity/status/update`,
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
        body: JSON.stringify({ data: data }),
      }
    );
    if (response.status === 200) {
      return await response.json();
    } else if (response.status === 401 || response.status === 400) {
      return await response.json();
    } else {
      var errorResponse = await response.json();
      throw new Error(errorResponse.error);
    }
  } catch (e) {
    throw e;
  }
};

export const updatePlanningUploadingActivitiesStatusV2 = async (data) => {
  let response;
  const token = localStorage.getItem(`token`);
  try {
    response = await fetch(
      `${URL1}/dairy/content/uploading/activity/status/update`,
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
        body: JSON.stringify({ data: data }),
      }
    );
    if (response.status === 200) {
      return await response.json();
    } else if (response.status === 401 || response.status === 400) {
      return await response.json();
    } else {
      var errorResponse = await response.json();
      throw new Error(errorResponse.error);
    }
  } catch (e) {
    throw e;
  }
};

export async function getAllInCompletedUploadActivity(
  companyId,
  typeOfBook,
  countryId,
  institutionTypeId,
  attributeId,
  fromDate
) {
  const token = localStorage.getItem("token");
  const response = await fetch(
    `${URL}/dairy/content/uploading/get/all/${companyId}/${typeOfBook}/${countryId}/${institutionTypeId}/${attributeId}/${fromDate}`,
    {
      method: `GET`,
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    }
  );
  // console.log(`Response`, response);
  if (response.status === 200) {
    return await response.json();
  } else if (response.status === 401 || response.status === 400) {
    return await response.json();
  } else {
    let errorResponse = await response.json();
    throw new Error(errorResponse.error);
  }
}


export async function getAllUploadActivityList(date) {
  const token = localStorage.getItem("token");
  const response = await fetch(
    `${URL1}/dairy/content/uploading/get/all/${date}`,
    {
      method: `GET`,
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    }
  );
  // console.log(`Response`, response);
  if (response.status === 200) {
    return await response.json();
  } else if (response.status === 401 || response.status === 400) {
    return await response.json();
  } else {
    let errorResponse = await response.json();
    throw new Error(errorResponse.error);
  }
}

export async function getAllMonthlySelfTask(
  companyId,
  typeOfBook,
  countryId,
  institutionTypeId,
  attributeId,
  date
) {
  const token = localStorage.getItem("token");
  const response = await fetch(
    `${URL}/dairy/content/preparation/completed/task/get/self/mothly/${companyId}/${typeOfBook}/${countryId}/${institutionTypeId}/${attributeId}/${date}`,
    {
      method: `GET`,
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    }
  );
  // console.log(`Response`, response);
  if (response.status === 200) {
    return await response.json();
  } else if (response.status === 401 || response.status === 400) {
    return await response.json();
  } else {
    let errorResponse = await response.json();
    throw new Error(errorResponse.error);
  }
}

export async function getAllMonthlyTeamTask(body) {
  const token = localStorage.getItem("token");
  const response = await fetch(
    `${URL}/dairy/content/preparation/completed/task/get/team/mothly`,
    {
      method: `POST`,
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
      body: JSON.stringify(body),
    }
  );
  // console.log(`Response`, response);
  if (response.status === 200) {
    return await response.json();
  } else if (response.status === 401 || response.status === 400) {
    return await response.json();
  } else {
    let errorResponse = await response.json();
    throw new Error(errorResponse.error);
  }
}

export async function getAllUploadingMonthlyTeamTask(body) {
  const token = localStorage.getItem("token");
  const response = await fetch(
    `${URL}/dairy/content/uploading/completed/task/get/team/monthly`,
    {
      method: `POST`,
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
      body: JSON.stringify(body),
    }
  );
  // console.log(`Response`, response);
  if (response.status === 200) {
    return await response.json();
  } else if (response.status === 401 || response.status === 400) {
    return await response.json();
  } else {
    let errorResponse = await response.json();
    throw new Error(errorResponse.error);
  }
}

export async function getAllUploadingMonthlySelfTask(companyId, date, endDate) {
  const token = localStorage.getItem("token");
  const response = await fetch(
    `${URL}/dairy/content/uploading/completed/task/get/self/monthly/${companyId}/${date}/${endDate}`,
    {
      method: `GET`,
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    }
  );
  // console.log(`Response`, response);
  if (response.status === 200) {
    return await response.json();
  } else if (response.status === 401 || response.status === 400) {
    return await response.json();
  } else {
    let errorResponse = await response.json();
    throw new Error(errorResponse.error);
  }
}

export async function getAllPreparationWeeklySelfTask(
  companyId,
  typeOfBook,
  countryId,
  institutionTypeId,
  attributeId,
  fromDate,
  dueDate
) {
  const token = localStorage.getItem("token");
  const response = await fetch(
    `${URL}/dairy/content/preparation/completed/task/get/self/weekly/${companyId}/${typeOfBook}/${countryId}/${institutionTypeId}/${attributeId}/${fromDate}/${dueDate}`,
    {
      method: `GET`,
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    }
  );
  // console.log(`Response`, response);
  if (response.status === 200) {
    return await response.json();
  } else if (response.status === 401 || response.status === 400) {
    return await response.json();
  } else {
    let errorResponse = await response.json();
    throw new Error(errorResponse.error);
  }
}

export async function getAllPreparationWeeklyTeamTask(body) {
  const token = localStorage.getItem("token");
  const response = await fetch(
    `${URL}/dairy/content/preparation/completed/task/get/team/weekly`,
    {
      method: `POST`,
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
      body: JSON.stringify(body),
    }
  );
  // console.log(`Response`, response);
  if (response.status === 200) {
    return await response.json();
  } else if (response.status === 401 || response.status === 400) {
    return await response.json();
  } else {
    let errorResponse = await response.json();
    throw new Error(errorResponse.error);
  }
}

export async function getAllUploadingWeeklySelfTask(
  companyId,
  // typeOfBook,
  // countryId,
  // institutionTypeId,
  // attributeId,
  fromDate,
  dueDate
) {
  const token = localStorage.getItem("token");
  const response = await fetch(
    `${URL}/dairy/content/uploading/completed/task/get/self/weekly/${companyId}/${fromDate}/${dueDate}`,
    // `${URL}/dairy/content/uploading/completed/task/get/self/weekly/${companyId}/${typeOfBook}/${countryId}/${institutionTypeId}/${attributeId}/${fromDate}/${dueDate}`,
    {
      method: `GET`,
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    }
  );
  // console.log(`Response`, response);
  if (response.status === 200) {
    return await response.json();
  } else if (response.status === 401 || response.status === 400) {
    return await response.json();
  } else {
    let errorResponse = await response.json();
    throw new Error(errorResponse.error);
  }
}

export async function getAllUploadingWeeklyTeamTask(body) {
  const token = localStorage.getItem("token");
  const response = await fetch(
    `${URL}/dairy/content/uploading/completed/task/get/team/weekly`,
    {
      method: `POST`,
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
      body: JSON.stringify(body),
    }
  );
  // console.log(`Response`, response);
  if (response.status === 200) {
    return await response.json();
  } else if (response.status === 401 || response.status === 400) {
    return await response.json();
  } else {
    let errorResponse = await response.json();
    throw new Error(errorResponse.error);
  }
}

//preparation todays task repoet

export async function getAllTodaysCompletedActivity(
  companyId,
  typeOfBook,
  countryId,
  institutionTypeId,
  attributeId
) {
  const token = localStorage.getItem("token");
  const response = await fetch(
    `${URL}/dairy/content/preparation/completed/task/get/self/today/${companyId}/${typeOfBook}/${countryId}/${institutionTypeId}/${attributeId}`,
    {
      method: `GET`,
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    }
  );
  // console.log(`Response`, response);
  if (response.status === 200) {
    return await response.json();
  } else if (response.status === 401 || response.status === 400) {
    return await response.json();
  } else {
    let errorResponse = await response.json();
    throw new Error(errorResponse.error);
  }
}

export async function getAllTodayTeamTask(body) {
  const token = localStorage.getItem("token");
  const response = await fetch(
    `${URL}/dairy/content/preparation/completed/task/get/team/today`,
    {
      method: `POST`,
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
      body: JSON.stringify(body),
    }
  );
  // console.log(`Response`, response);
  if (response.status === 200) {
    return await response.json();
  } else if (response.status === 401 || response.status === 400) {
    return await response.json();
  } else {
    let errorResponse = await response.json();
    throw new Error(errorResponse.error);
  }
}

// uploading todays task

export async function getAllUploadTodaysCompletedActivity(
  companyId,
  // typeOfBook,
  // countryId,
  // institutionTypeId,
  // attributeId,
  todayDate
) {
  const token = localStorage.getItem("token");
  const response = await fetch(
    `${URL}/dairy/content/uploading/completed/task/get/self/today/${companyId}/${todayDate}`,
    // `${URL}/dairy/content/uploading/completed/task/get/self/today/${companyId}/${typeOfBook}/${countryId}/${institutionTypeId}/${attributeId}/${todayDate}`,
    {
      method: `GET`,
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    }
  );
  // console.log(`Response`, response);
  if (response.status === 200) {
    return await response.json();
  } else if (response.status === 401 || response.status === 400) {
    return await response.json();
  } else {
    let errorResponse = await response.json();
    throw new Error(errorResponse.error);
  }
}

export async function getAllUploadTodayTeamTask(body) {
  const token = localStorage.getItem("token");
  const response = await fetch(
    `${URL}/dairy/content/uploading/completed/task/get/team/today`,
    {
      method: `POST`,
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
      body: JSON.stringify(body),
    }
  );
  // console.log(`Response`, response);
  if (response.status === 200) {
    return await response.json();
  } else if (response.status === 401 || response.status === 400) {
    return await response.json();
  } else {
    let errorResponse = await response.json();
    throw new Error(errorResponse.error);
  }
}

export async function getAllApprisalMonthlySelfTask(
  companyId,
  typeOfBook,
  countryId,
  institutionTypeId,
  attributeId,
  date
) {
  const token = localStorage.getItem("token");
  const response = await fetch(
    `${URL}/dairy/content/preparation/appraisal/task/get/self/mothly/${companyId}/${typeOfBook}/${countryId}/${institutionTypeId}/${attributeId}/${date}`,
    {
      method: `GET`,
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    }
  );
  // console.log(`Response`, response);
  if (response.status === 200) {
    return await response.json();
  } else if (response.status === 401 || response.status === 400) {
    return await response.json();
  } else {
    let errorResponse = await response.json();
    throw new Error(errorResponse.error);
  }
}

export async function getAllAppraisalMonthlyTeamTask(body) {
  const token = localStorage.getItem("token");
  const response = await fetch(
    `${URL}/dairy/content/preparation/appraisal/task/get/team/mothly`,
    {
      method: `POST`,
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
      body: JSON.stringify(body),
    }
  );
  // console.log(`Response`, response);
  if (response.status === 200) {
    return await response.json();
  } else if (response.status === 401 || response.status === 400) {
    return await response.json();
  } else {
    let errorResponse = await response.json();
    throw new Error(errorResponse.error);
  }
}

// apprisal uploading monthly task

export async function getAllApprisUploadMonthlySelfTask(
  companyId,
  date,
  endDate
) {
  const token = localStorage.getItem("token");
  const response = await fetch(
    `${URL}/dairy/content/uploading/appraisal/task/get/self/mothly/${companyId}/${date}/${endDate}`,
    {
      method: `GET`,
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    }
  );
  // console.log(`Response`, response);
  if (response.status === 200) {
    return await response.json();
  } else if (response.status === 401 || response.status === 400) {
    return await response.json();
  } else {
    let errorResponse = await response.json();
    throw new Error(errorResponse.error);
  }
}

export async function getAllApprisUploadingMonthlyTeamTask(body) {
  const token = localStorage.getItem("token");
  const response = await fetch(
    `${URL}/dairy/content/uploading/appraisal/task/get/team/monthly`,
    {
      method: `POST`,
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
      body: JSON.stringify(body),
    }
  );
  // console.log(`Response`, response);
  if (response.status === 200) {
    return await response.json();
  } else if (response.status === 401 || response.status === 400) {
    return await response.json();
  } else {
    let errorResponse = await response.json();
    throw new Error(errorResponse.error);
  }
}

//Weekly preparation report

export async function getAllApprisPreparationWeeklySelfTask(
  companyId,
  typeOfBook,
  countryId,
  institutionTypeId,
  attributeId,
  fromDate,
  dueDate
) {
  const token = localStorage.getItem("token");
  const response = await fetch(
    `${URL}/dairy/content/preparation/completed/task/get/self/weekly/${companyId}/${typeOfBook}/${countryId}/${institutionTypeId}/${attributeId}/${fromDate}/${dueDate}`,
    {
      method: `GET`,
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    }
  );
  // console.log(`Response`, response);
  if (response.status === 200) {
    return await response.json();
  } else if (response.status === 401 || response.status === 400) {
    return await response.json();
  } else {
    let errorResponse = await response.json();
    throw new Error(errorResponse.error);
  }
}

export async function getAllApprisPreparationWeeklyTeamTask(body) {
  const token = localStorage.getItem("token");
  const response = await fetch(
    `${URL}/dairy/content/preparation/completed/task/get/team/weekly`,
    {
      method: `POST`,
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
      body: JSON.stringify(body),
    }
  );
  // console.log(`Response`, response);
  if (response.status === 200) {
    return await response.json();
  } else if (response.status === 401 || response.status === 400) {
    return await response.json();
  } else {
    let errorResponse = await response.json();
    throw new Error(errorResponse.error);
  }
}

//weekly uploading report

export async function getAllApprisalUploadingWeeklySelfTask(
  companyId,
  // typeOfBook,
  // countryId,
  // institutionTypeId,
  // attributeId,
  fromDate,
  dueDate
) {
  const token = localStorage.getItem("token");
  const response = await fetch(
    `${URL}/dairy/content/uploading/appraisal/task/get/self/weekly/${companyId}/${fromDate}/${dueDate}`,
    // `${URL}/dairy/content/uploading/appraisal/task/get/self/weekly/${companyId}/${typeOfBook}/${countryId}/${institutionTypeId}/${attributeId}/${fromDate}/${dueDate}`,
    {
      method: `GET`,
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    }
  );
  // console.log(`Response`, response);
  if (response.status === 200) {
    return await response.json();
  } else if (response.status === 401 || response.status === 400) {
    return await response.json();
  } else {
    let errorResponse = await response.json();
    throw new Error(errorResponse.error);
  }
}

export async function getAllApprisUploadingWeeklyTeamTask(body) {
  const token = localStorage.getItem("token");
  const response = await fetch(
    `${URL}/dairy/content/uploading/appraisal/task/get/team/weekly`,
    {
      method: `POST`,
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
      body: JSON.stringify(body),
    }
  );
  // console.log(`Response`, response);
  if (response.status === 200) {
    return await response.json();
  } else if (response.status === 401 || response.status === 400) {
    return await response.json();
  } else {
    let errorResponse = await response.json();
    throw new Error(errorResponse.error);
  }
}

export async function getAllStatusReport(body) {
  const token = localStorage.getItem("token");
  const response = await fetch(
    `${URL}/dairy/content/preparation/status/task/get/self/detailed`,
    {
      method: `POST`,
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
      body: JSON.stringify(body),
    }
  );
  // console.log(`Response`, response);
  if (response.status === 200) {
    return await response.json();
  } else if (response.status === 401 || response.status === 400) {
    return await response.json();
  } else {
    let errorResponse = await response.json();
    throw new Error(errorResponse.error);
  }
}

export async function getAllStatusReportTeam(body) {
  const token = localStorage.getItem("token");
  const response = await fetch(
    `${URL}/dairy/content/preparation/status/task/get/team/detailed`,
    {
      method: `POST`,
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
      body: JSON.stringify(body),
    }
  );
  // console.log(`Response`, response);
  if (response.status === 200) {
    return await response.json();
  } else if (response.status === 401 || response.status === 400) {
    return await response.json();
  } else {
    let errorResponse = await response.json();
    throw new Error(errorResponse.error);
  }
}

export async function getAllUploadStatusReport(body) {
  const token = localStorage.getItem("token");
  const response = await fetch(
    `${URL}/dairy/content/uploading/status/task/get/self/detailed`,
    {
      method: `POST`,
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
      body: JSON.stringify(body),
    }
  );
  // console.log(`Response`, response);
  if (response.status === 200) {
    return await response.json();
  } else if (response.status === 401 || response.status === 400) {
    return await response.json();
  } else {
    let errorResponse = await response.json();
    throw new Error(errorResponse.error);
  }
}

export async function getAllUploadStatusReportTeam(body) {
  const token = localStorage.getItem("token");
  const response = await fetch(
    `${URL}/dairy/content/uploading/status/task/get/team/detailed`,
    {
      method: `POST`,
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
      body: JSON.stringify(body),
    }
  );
  // console.log(`Response`, response);
  if (response.status === 200) {
    return await response.json();
  } else if (response.status === 401 || response.status === 400) {
    return await response.json();
  } else {
    let errorResponse = await response.json();
    throw new Error(errorResponse.error);
  }
}

export async function getAllConsolidatedStatusReport(body) {
  const token = localStorage.getItem("token");
  const response = await fetch(
    `${URL}/dairy/content/preparation/status/task/get/self/consolidated`,
    {
      method: `POST`,
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
      body: JSON.stringify(body),
    }
  );
  // console.log(`Response`, response);
  if (response.status === 200) {
    return await response.json();
  } else if (response.status === 401 || response.status === 400) {
    return await response.json();
  } else {
    let errorResponse = await response.json();
    throw new Error(errorResponse.error);
  }
}

export async function getAllConsolidatedStatusReportTeam(body) {
  const token = localStorage.getItem("token");
  const response = await fetch(
    `${URL}/dairy/content/preparation/status/task/team/consolidated`,
    {
      method: `POST`,
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
      body: JSON.stringify(body),
    }
  );
  // console.log(`Response`, response);
  if (response.status === 200) {
    return await response.json();
  } else if (response.status === 401 || response.status === 400) {
    return await response.json();
  } else {
    let errorResponse = await response.json();
    throw new Error(errorResponse.error);
  }
}

export async function getAllUploadConsoildatedStatusReport(body) {
  const token = localStorage.getItem("token");
  const response = await fetch(
    `${URL1}/dairy/content/uploading/status/task/get/team/consolidated`,
    {
      method: `POST`,
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
      body: JSON.stringify(body),
    }
  );
  // console.log(`Response`, response);
  if (response.status === 200) {
    return await response.json();
  } else if (response.status === 401 || response.status === 400) {
    return await response.json();
  } else {
    let errorResponse = await response.json();
    throw new Error(errorResponse.error);
  }
}

//Todays task

export async function getAllPrepAppraisalTodayTeamTask(body) {
  const token = localStorage.getItem("token");
  const response = await fetch(
    `${URL}/dairy/content/preparation/appraisal/task/get/team/today`,
    {
      method: `POST`,
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
      body: JSON.stringify(body),
    }
  );
  // console.log(`Response`, response);
  if (response.status === 200) {
    return await response.json();
  } else if (response.status === 401 || response.status === 400) {
    return await response.json();
  } else {
    let errorResponse = await response.json();
    throw new Error(errorResponse.error);
  }
}

export async function getAllUploadAppraisalTodaySelfTask(
  companyId,
  // typeOfBook,
  // countryId,
  // institutionTypeId,
  // attributeId,
  todayDate
) {
  const token = localStorage.getItem("token");
  const response = await fetch(
    `${URL}/dairy/content/uploading/appraisal/task/get/self/today/${companyId}/${todayDate}`,
    // `${URL}/dairy/content/uploading/appraisal/task/get/self/today/${companyId}/${typeOfBook}/${countryId}/${institutionTypeId}/${attributeId}/${todayDate}`,
    {
      method: `GET`,
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    }
  );
  // console.log(`Response`, response);
  if (response.status === 200) {
    return await response.json();
  } else if (response.status === 401 || response.status === 400) {
    return await response.json();
  } else {
    let errorResponse = await response.json();
    throw new Error(errorResponse.error);
  }
}

export async function getAllPrepAppraisalTodaySelfTask(
  companyId,
  typeOfBook,
  countryId,
  institutionTypeId,
  attributeId
) {
  const token = localStorage.getItem("token");
  const response = await fetch(
    `${URL}/dairy/content/preparation/appraisal/task/get/self/today/${companyId}/${typeOfBook}/${countryId}/${institutionTypeId}/${attributeId}`,
    {
      method: `GET`,
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    }
  );
  // console.log(`Response`, response);
  if (response.status === 200) {
    return await response.json();
  } else if (response.status === 401 || response.status === 400) {
    return await response.json();
  } else {
    let errorResponse = await response.json();
    throw new Error(errorResponse.error);
  }
}

export async function getAllUploadAppraisalTodayTeamTask(body) {
  const token = localStorage.getItem("token");
  const response = await fetch(
    `${URL}/dairy/content/uploading/appraisal/task/get/team/today`,
    {
      method: `POST`,
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
      body: JSON.stringify(body),
    }
  );
  // console.log(`Response`, response);
  if (response.status === 200) {
    return await response.json();
  } else if (response.status === 401 || response.status === 400) {
    return await response.json();
  } else {
    let errorResponse = await response.json();
    throw new Error(errorResponse.error);
  }
}

export async function downloadPdf(body, header) {
  const token = localStorage.getItem("token");
  const response = await fetch(`${URL1}/download/pdf`, {
    method: `POST`,
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
    body: JSON.stringify({ data: body, header: header }),
  });
  // console.log(`Response`, response);
  if (response.status === 200) {
    return await response.json();
  } else if (response.status === 401 || response.status === 400) {
    return await response.json();
  } else {
    let errorResponse = await response.json();
    throw new Error(errorResponse.error);
  }
}

export async function downloadtaskReportSelfPdf(body, header) {
  const token = localStorage.getItem("token");
  const response = await fetch(`${URL1}/download/taskreport/self/pdf`, {
    method: `POST`,
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
    body: JSON.stringify({ data: body, header: header }),
  });
  // console.log(`Response`, response);
  if (response.status === 200) {
    return await response.json();
  } else if (response.status === 401 || response.status === 400) {
    return await response.json();
  } else {
    let errorResponse = await response.json();
    throw new Error(errorResponse.error);
  }
}

export async function downloadAppraisalPdf(body, header) {
  const token = localStorage.getItem("token");
  const response = await fetch(`${URL1}/download/appraisal/team/report/pdf`, {
    method: `POST`,
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
    body: JSON.stringify({ data: body, header: header }),
  });
  // console.log(`Response`, response);
  if (response.status === 200) {
    return await response.json();
  } else if (response.status === 401 || response.status === 400) {
    return await response.json();
  } else {
    let errorResponse = await response.json();
    throw new Error(errorResponse.error);
  }
}

export async function downloadAppraisalSelfPdf(body, header) {
  const token = localStorage.getItem("token");
  const response = await fetch(`${URL1}/download/appraisal/self/report/pdf`, {
    method: `POST`,
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
    body: JSON.stringify({ data: body, header: header }),
  });
  // console.log(`Response`, response);
  if (response.status === 200) {
    return await response.json();
  } else if (response.status === 401 || response.status === 400) {
    return await response.json();
  } else {
    let errorResponse = await response.json();
    throw new Error(errorResponse.error);
  }
}

export async function downloadstatusReportPdf(body, header) {
  const token = localStorage.getItem("token");
  const response = await fetch(`${URL1}/download/status/report/pdf`, {
    method: `POST`,
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
    body: JSON.stringify({ data: body, header: header }),
  });
  // console.log(`Response`, response);
  if (response.status === 200) {
    return await response.json();
  } else if (response.status === 401 || response.status === 400) {
    return await response.json();
  } else {
    let errorResponse = await response.json();
    throw new Error(errorResponse.error);
  }
}

export const createHolidayEmply = async (body) => {
  try {
    const token = localStorage.getItem(`token`);
    const response = await fetch(`${URL}/admin/employee/holiday/create`, {
      method: `POST`,
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
      body: JSON.stringify(body),
    });
    if (response.status === 200) {
      return await response.json();
    } else if (response.status === 401 || response.status === 400) {
      return await response.json();
    } else {
      var errorResponse = await response.json();
      throw new Error(errorResponse.error);
    }
  } catch (e) {
    throw e;
  }
};

export const getAllEmployeHolidays = async (body, companyId) => {
  try {
    const token = localStorage.getItem(`token`);
    const response = await fetch(
      `${URL}/admin/employee/holiday/get/all/${companyId}`,
      {
        method: `GET`,
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
        body: JSON.stringify(body),
      }
    );
    // console.log(`delete school response...`, response);
    if (response.status === 200) {
      return await response.json();
    } else if (response.status === 401 || response.status === 400) {
      return await response.json();
    } else {
      var errorResponse = await response.json();
      throw new Error(errorResponse.error);
    }
  } catch (e) {
    throw e;
  }
};

export async function getAllEmployeeHolidays(companyId) {
  const token = localStorage.getItem("token");
  const response = await fetch(
    `${URL}/admin/employee/holiday/get/all/${companyId}`,
    {
      method: `GET`,
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    }
  );
  // console.log(`Response`, response);
  if (response.status === 200) {
    return await response.json();
  } else if (response.status === 401 || response.status === 400) {
    return await response.json();
  } else {
    let errorResponse = await response.json();
    throw new Error(errorResponse.error);
  }
}

export async function updateEmployeeHoliday(body) {
  const token = localStorage.getItem(`token`);
  try {
    const response = await fetch(`${URL}/admin/employee/holiday/update`, {
      method: `PUT`,
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
      body: JSON.stringify(body),
    });
    if (response.status === 200) {
      return await response.json();
    } else if (response.status === 401 || response.status === 400) {
      return await response.json();
    } else {
      var errorResponse = await response.json();
      throw new Error(errorResponse.error);
    }
  } catch (e) {
    throw e;
  }
}

export async function deleteHolidayEmploye(body) {
  let response;
  try {
    const token = localStorage.getItem(`token`);
    response = await fetch(`${URL}/admin/employee/holiday/delete`, {
      method: `delete`,
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
      body: JSON.stringify(body),
    });
    // console.log(`delete school response...`, response);
    if (response.status === 200) {
      return await response.json();
    } else if (response.status === 401 || response.status === 400) {
      return await response.json();
    } else {
      var errorResponse = await response.json();
      throw new Error(errorResponse.error);
    }
  } catch (e) {
    throw e;
  }
}

export async function getAllEmployeeHolidaysByEmployee(companyId) {
  const token = localStorage.getItem("token");
  const response = await fetch(`${URL}/employee/holiday/get/all/${companyId}`, {
    method: `GET`,
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
  });
  // console.log(`Response`, response);
  if (response.status === 200) {
    return await response.json();
  } else if (response.status === 401 || response.status === 400) {
    return await response.json();
  } else {
    let errorResponse = await response.json();
    throw new Error(errorResponse.error);
  }
}

export async function getAllEmployeeHolidaysByAdmin(companyId, employeeId) {
  const token = localStorage.getItem("token");
  const response = await fetch(
    `${URL}/admin/employee/holiday/get/all/employee/${companyId}/${employeeId}`,
    {
      method: `GET`,
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    }
  );
  // console.log(`Response`, response);
  if (response.status === 200) {
    return await response.json();
  } else if (response.status === 401 || response.status === 400) {
    return await response.json();
  } else {
    let errorResponse = await response.json();
    throw new Error(errorResponse.error);
  }
}

export async function getAllDraftContent(body) {
  const token = localStorage.getItem("token");
  const response = await fetch(`${URL}/draft/content/get/unapprovel/staff`, {
    method: `GET`,
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
    body: JSON.stringify(body),
  });
  // console.log(`Response`, response);
  if (response.status === 200) {
    return await response.json();
  } else if (response.status === 401 || response.status === 400) {
    return await response.json();
  } else {
    let errorResponse = await response.json();
    throw new Error(errorResponse.error);
  }
}

export async function createDraftContent(body) {
  // console.log(body, "dhshdhsg");
  try {
    const token = localStorage.getItem("token");
    const response = await fetch(`${URL}/draft/content/upload/staff`, {
      method: `POST`,
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
      body: JSON.stringify(body),
    });
    if (response.status === 200) {
      return await response.json();
    } else if (response.status === 401 || response.status === 400) {
      return await response.json();
    } else {
      var errorResponse = await response.json();
      throw new Error(errorResponse.error);
    }
  } catch (e) {
    throw e;
  }
}

export async function getAllReworkDraftContent(body) {
  const token = localStorage.getItem("token");
  const response = await fetch(`${URL}/draft/content/get/rework/staff`, {
    method: `GET`,
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
    body: JSON.stringify(body),
  });
  // console.log(`Response`, response);
  if (response.status === 200) {
    return await response.json();
  } else if (response.status === 401 || response.status === 400) {
    return await response.json();
  } else {
    let errorResponse = await response.json();
    throw new Error(errorResponse.error);
  }
}

export async function getAllApprovedDraftContent(body) {
  const token = localStorage.getItem("token");
  const response = await fetch(`${URL}/draft/content/get/approvel/staff`, {
    method: `GET`,
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
    body: JSON.stringify(body),
  });
  // console.log(`Response`, response);
  if (response.status === 200) {
    return await response.json();
  } else if (response.status === 401 || response.status === 400) {
    return await response.json();
  } else {
    let errorResponse = await response.json();
    throw new Error(errorResponse.error);
  }
}

export async function getAllApprovedDraft() {
  const token = localStorage.getItem("token");
  const response = await fetch(`${URL}/draft/content/get/all/working/admin`, {
    method: `GET`,
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
    // body: JSON.stringify(body),
  });
  // console.log(`Response`, response);
  if (response.status === 200) {
    return await response.json();
  } else if (response.status === 401 || response.status === 400) {
    return await response.json();
  } else {
    let errorResponse = await response.json();
    throw new Error(errorResponse.error);
  }
}

export async function ReworkContent(body) {
  // console.log(body, "dhshdhsg");
  try {
    const token = localStorage.getItem("token");
    const response = await fetch(`${URL}/draft/content/staus/update/admin`, {
      method: `PUT`,
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
      body: JSON.stringify(body),
    });
    if (response.status === 200) {
      return await response.json();
    } else if (response.status === 401 || response.status === 400) {
      return await response.json();
    } else {
      var errorResponse = await response.json();
      throw new Error(errorResponse.error);
    }
  } catch (e) {
    throw e;
  }
}

export async function ReworkSaveContent(body) {
  // console.log(body, "dhshdhsg");
  try {
    const token = localStorage.getItem("token");
    const response = await fetch(`${URL}/draft/staus/update/admin`, {
      method: `PUT`,
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
      body: JSON.stringify(body),
    });
    if (response.status === 200) {
      return await response.json();
    } else if (response.status === 401 || response.status === 400) {
      return await response.json();
    } else {
      var errorResponse = await response.json();
      throw new Error(errorResponse.error);
    }
  } catch (e) {
    throw e;
  }
}

export async function getAllApproved() {
  const token = localStorage.getItem("token");
  const response = await fetch(`${URL}/draft/content/get/all/approvel/admin`, {
    method: `GET`,
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
    // body: JSON.stringify(body),
  });
  // console.log(`Response`, response);
  if (response.status === 200) {
    return await response.json();
  } else if (response.status === 401 || response.status === 400) {
    return await response.json();
  } else {
    let errorResponse = await response.json();
    throw new Error(errorResponse.error);
  }
}

export const getAllStaffUploadDraft = async (companyId) => {
  try {
    const token = localStorage.getItem(`token`);

    const response = await fetch(`${URL}/staff/content/get/all/${companyId}`, {
      method: `GET`,
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    });
    if (response.status === 200) {
      return await response.json();
    } else if (response.status === 401 || response.status === 400) {
      return await response.json();
    } else {
      var errorResponse = await response.json();
      throw new Error(errorResponse.error);
    }
  } catch (e) {
    throw e;
  }
};

export async function createDraft(body) {
  // console.log(body, "dhshdhsg");
  try {
    const token = localStorage.getItem("token");
    const response = await fetch(`${URL}/draft/upload/staff`, {
      method: `POST`,
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
      body: JSON.stringify(body),
    });
    if (response.status === 200) {
      return await response.json();
    } else if (response.status === 401 || response.status === 400) {
      return await response.json();
    } else {
      var errorResponse = await response.json();
      throw new Error(errorResponse.error);
    }
  } catch (e) {
    throw e;
  }
}

export const getAllAdminUploadDraft = async (variableId) => {
  try {
    const token = localStorage.getItem(`token`);

    const response = await fetch(
      `${URL}/draft/get/all/working/admin/${variableId}`,
      {
        method: `GET`,
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      }
    );
    if (response.status === 200) {
      return await response.json();
    } else if (response.status === 401 || response.status === 400) {
      return await response.json();
    } else {
      var errorResponse = await response.json();
      throw new Error(errorResponse.error);
    }
  } catch (e) {
    throw e;
  }
};

export const getAllDraftByStaff = async (variableId) => {
  try {
    const token = localStorage.getItem(`token`);

    const response = await fetch(`${URL}/draft/get/all/staff/${variableId}`, {
      method: `GET`,
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    });
    if (response.status === 200) {
      return await response.json();
    } else if (response.status === 401 || response.status === 400) {
      return await response.json();
    } else {
      var errorResponse = await response.json();
      throw new Error(errorResponse.error);
    }
  } catch (e) {
    throw e;
  }
};

export async function getAllQuestionType(
  companyId,
  typeOfBook,
  countryId,
  institutionTypeId,
  attributeId
) {
  const token = localStorage.getItem(`token`);
  try {
    const response = await fetch(
      `${URL}/questiontype/institution/get/all/${companyId}/${typeOfBook}/${countryId}/${institutionTypeId}/${attributeId}`,
      {
        method: `GET`,
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      }
    );
    if (response.status === 200) {
      return await response.json();
    } else if (response.status === 401 || response.status === 400) {
      return await response.json();
    } else {
      var errorResponse = await response.json();
      throw new Error(errorResponse.error);
    }
  } catch (e) {
    throw e;
  }
}

export async function createQuestionType(body) {
  // console.log(body, "dhshdhsg");
  try {
    const token = localStorage.getItem("token");
    const response = await fetch(`${URL}/questiontype/create`, {
      method: `POST`,
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
      body: JSON.stringify(body),
    });
    if (response.status === 200) {
      return await response.json();
    } else if (response.status === 401 || response.status === 400) {
      return await response.json();
    } else {
      var errorResponse = await response.json();
      throw new Error(errorResponse.error);
    }
  } catch (e) {
    throw e;
  }
}

export async function updateQuestionType(body) {
  // console.log(body, "dhshdhsg");
  try {
    const token = localStorage.getItem("token");
    const response = await fetch(`${URL}/questiontype/update`, {
      method: `PUT`,
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
      body: JSON.stringify(body),
    });
    if (response.status === 200) {
      return await response.json();
    } else if (response.status === 401 || response.status === 400) {
      return await response.json();
    } else {
      var errorResponse = await response.json();
      throw new Error(errorResponse.error);
    }
  } catch (e) {
    throw e;
  }
}

// katbook hybrid offline api

//Lis of Units and sessions
export async function getBookUnits(BookId) {
  // console.log(variables);
  const token = localStorage.getItem(`token`);
  const response = await fetch(`${URL}/variable/getUnitByBook/${BookId}`, {
    method: `GET`,
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
    // body: JSON.stringify({ variables: variables }),
  });
  // console.log(`Response`, response);
  if (response.status === 200) {
    return await response.json();
  } else if (response.status === 401 || response.status === 400) {
    return await response.json();
  } else {
    let errorResponse = await response.json();
    throw new Error(errorResponse.error);
  }
}

export async function generateUnitZIps(BookId, UnitId) {
  // console.log(variables);
  const token = localStorage.getItem(`token`);
  const response = await fetch(
    `${URL}/offline/bookfile/create/${BookId}/${UnitId}`,
    {
      method: `POST`,
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
      // body: JSON.stringify({ variables: variables }),
    }
  );
  // console.log(`Response`, response);
  if (response.status === 200) {
    return await response.json();
  } else if (response.status === 401 || response.status === 400) {
    return await response.json();
  } else {
    let errorResponse = await response.json();
    throw new Error(errorResponse.error);
  }
}

export async function updateUnitZIps(BookId, UnitId) {
  // console.log(variables);
  const token = localStorage.getItem(`token`);
  const response = await fetch(
    `${URL}/offline/bookfile/update/${BookId}/${UnitId}`,
    {
      method: `PUT`,
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
      // body: JSON.stringify({ variables: variables }),
    }
  );
  // console.log(`Response`, response);
  if (response.status === 200) {
    return await response.json();
  } else if (response.status === 401 || response.status === 400) {
    return await response.json();
  } else {
    let errorResponse = await response.json();
    throw new Error(errorResponse.error);
  }
}

export async function GetUnitZipGenerateList(BookId) {
  // console.log(variables);
  const token = localStorage.getItem(`token`);
  const response = await fetch(
    `${URL}/offline/bookfile/book/getall/${BookId}`,
    {
      method: `GET`,
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
      // body: JSON.stringify({ variables: variables }),
    }
  );
  // console.log(`Response`, response);
  if (response.status === 200) {
    return await response.json();
  } else if (response.status === 401 || response.status === 400) {
    return await response.json();
  } else {
    let errorResponse = await response.json();
    throw new Error(errorResponse.error);
  }
}

export async function generateSessionZIps(BookId, SessionId) {
  // console.log(variables);
  const token = localStorage.getItem(`token`);
  const response = await fetch(
    `${URL}/offline/bookfile/sessions/create/v2/${BookId}/${SessionId}`,
    {
      method: `POST`,
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
      // body: JSON.stringify({ variables: variables }),
    }
  );
  // console.log(`Response`, response);
  if (response.status === 200) {
    return await response.json();
  } else if (response.status === 401 || response.status === 400) {
    return await response.json();
  } else {
    let errorResponse = await response.json();
    throw new Error(errorResponse.error);
  }
}

export async function GetSessionZipGenerateList(BookId) {
  // console.log(variables);
  const token = localStorage.getItem(`token`);
  const response = await fetch(
    `${URL}/offline/bookfile/book/session/getall/${BookId}/1`,
    {
      method: `GET`,
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
      // body: JSON.stringify({ variables: variables }),
    }
  );
  // console.log(`Response`, response);
  if (response.status === 200) {
    return await response.json();
  } else if (response.status === 401 || response.status === 400) {
    return await response.json();
  } else {
    let errorResponse = await response.json();
    throw new Error(errorResponse.error);
  }
}

export async function updateSessionZIps(BookId, SessionId) {
  // console.log(variables);
  const token = localStorage.getItem(`token`);
  const response = await fetch(
    `${URL}/offline/bookfile/sessions/update/${BookId}/${SessionId}`,
    {
      method: `PUT`,
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
      // body: JSON.stringify({ variables: variables }),
    }
  );
  // console.log(`Response`, response);
  if (response.status === 200) {
    return await response.json();
  } else if (response.status === 401 || response.status === 400) {
    return await response.json();
  } else {
    let errorResponse = await response.json();
    throw new Error(errorResponse.error);
  }
}


export async function ContentUploaderEmpReport(body) {
  // console.log(body, "dhshdhsg");
  try {
    const token = localStorage.getItem("token");
    const response = await fetch(`${URL}/dairy/uploading/workreport`, {
      method: `POST`,
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
      body: JSON.stringify(body),
    });
    if (response.status === 200) {
      return await response.json();
    } else if (response.status === 401 || response.status === 400 || response.status === 404) {
      return await response.json();
    } else {
      var errorResponse = await response.json();
      throw new Error(errorResponse.error);
    }
  } catch (e) {
    throw e;
  }
}

export async function getSessionContentById(sessionId) {
  try {
    const token = localStorage.getItem("token");
    const response = await fetch(`${URL}/content/${sessionId}`, {
      method: `POST`,
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      }
    });
    if (response.status === 200) {
      return await response.json();
    } else if (response.status === 401 || response.status === 400 || response.status === 404) {
      return await response.json();
    } else {
      var errorResponse = await response.json();
      throw new Error(errorResponse.error);
    }
  } catch (e) {
    throw e;
  }
}


export async function TrackingTimeCreateForContent(body) {
  // console.log(body, "dhshdhsg");
  try {
    const token = localStorage.getItem("token");
    const response = await fetch(`${URL}/activity/uploading/tracking/add`, {
      method: `POST`,
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
      body: JSON.stringify(body),
    });
    if (response.status === 200) {
      return await response.json();
    } else if (response.status === 401 || response.status === 400 || response.status === 404) {
      return await response.json();
    } else {
      var errorResponse = await response.json();
      throw new Error(errorResponse.error);
    }
  } catch (e) {
    throw e;
  }
}

export async function TrackingTimeUpdateForContent(body) {
  // console.log(body, "dhshdhsg");
  try {
    const token = localStorage.getItem("token");
    const response = await fetch(`${URL}/activity/uploading/tracking/update`, {
      method: `PATCH`,
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
      body: JSON.stringify(body),
    });
    if (response.status === 200) {
      return await response.json();
    } else if (response.status === 401 || response.status === 400 || response.status === 404) {
      return await response.json();
    } else {
      var errorResponse = await response.json();
      throw new Error(errorResponse.error);
    }
  } catch (e) {
    throw e;
  }
}

export async function saveCopyKatbook(body) {
  const token = localStorage.getItem(`token`);
  const response = await fetch(
    `${URL}/content/copy`,
    {
      method: `POST`,
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
      body: JSON.stringify(body),
    }
  );
  // console.log(`Response`, response);
  if (response.status === 200) {
    return await response.json();
  } else if (response.status === 401 || response.status === 400) {
    return await response.json();
  } else {
    let errorResponse = await response.json();
    throw new Error(errorResponse.error);
  }
}

export async function getVariablesFilter(
  company,
  typeOfBook,
  country,
  institutionType,
  attribute,
  parentTierId,
  parentVariableId,
  tierId,
  ca
) {
  const token = localStorage.getItem(`token`);
  try {
    const response = await fetch(
      `${URL}/variables/filter/${company}/${typeOfBook}/${country}/${institutionType}/${attribute}/${parentTierId}/${parentVariableId}/${tierId}${ca !== "" ? `?ca=${ca}` : ""}`,
      {
        method: `GET`,
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      }
    );
    if (response.status === 200) {
      return await response.json();
    } else if (response.status === 401 || response.status === 400) {
      return await response.json();
    } else {
      var errorResponse = await response.json();
      throw new Error(errorResponse.error);
    }
  } catch (e) {
    throw e;
  }
}

export async function postFileUpload(
  file,
  companyId,
  countryId,
  institutionTypeId,
  attributeId,
  typeOfBookId,
  tierDetails,
  variableDetails,
  contentType,
  languages,
  doOCR
) {
  const token = localStorage.getItem(`token`);
  const formData = new FormData();
  formData.append("pdf", file)
  formData.append("companyId", companyId)
  formData.append("countryId", countryId)
  formData.append("institutionTypeId", institutionTypeId)
  formData.append("attributeId", attributeId)
  formData.append("typeOfBookId", typeOfBookId)
  formData.append("tierDetails", JSON.stringify(tierDetails))
  formData.append("variableDetails", JSON.stringify(variableDetails))
  formData.append("contentType", contentType)
  formData.append("languages", JSON.stringify(languages))
  formData.append("doOCR", doOCR)
  const response = await fetch(`${URL}/content/upload/convert`,
    {
      method: `POST`,
      headers: {
        // "Content-Type": "application/json",
        Authorization: token,
      },
      body: formData,
    }
  );
  // console.log(`Response`, response);
  if (response.status === 200) {
    return await response.json();
  } else if (response.status === 401 || response.status === 400) {
    return await response.json();
  } else {
    let errorResponse = await response.json();
    throw new Error(errorResponse.error);
  }
}

export async function saveShowPDFContent(sessionId) {
  const token = localStorage.getItem(`token`);
  const response = await fetch(`${URL}/content/${sessionId}`,
    {
      method: `POST`,
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
      // body: JSON.stringify(body),
    }
  );
  // console.log(`Response`, response);
  if (response.status === 200) {
    return await response.json();
  } else if (response.status === 401 || response.status === 400) {
    return await response.json();
  } else {
    let errorResponse = await response.json();
    throw new Error(errorResponse.error);
  }
}

export const deleteMyTask = async (id) => {
  const token = localStorage.getItem(`token`);
  let response;
  try {
    response = await fetch(`${URL}/planning/content/uploading/delete/${id}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    });
    if (response.status === 200) {
      return await response.json();
    } else if (response.status === 401 || response.status === 400 || response.status === 404) {
      return await response.json();
    } else {
      var errorResponse = await response.json();
      throw new Error(errorResponse.error);
    }
  } catch (e) {
    throw e;
  }
};
